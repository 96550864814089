// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://f7088b439bed4e0686bc53925bb2ab16@o437709.ingest.sentry.io/5590478',
  enabled: process.env.NODE_ENV === 'production',
  tracesSampleRate: 0.5,
  integrations: [new Integrations.BrowserTracing()],
});
