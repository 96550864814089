import React from 'react';

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => (
  <div className="w-full py-8 md:py-28 flex flex-col justify-between items-center">
    <h2 className="font-bold text-3xl md:text-4xl  uppercase text-secondary-400 tracking-wide">
      {title}
    </h2>
    <div className="bg-primary-500 h-1 w-28 mt-4" />
  </div>
);

export default Title;
