import React from 'react';

interface PaginationProps {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  pageCount: number;
  previousPage: (num: number) => void;
  nextPage: (num: number) => void;
  page: number;
}

export default function Pagination({
  hasPreviousPage,
  hasNextPage,
  previousPage,
  nextPage,
  page,
  pageCount,
}: PaginationProps) {
  const [numbers, setNumbers] = React.useState(<div />);

  React.useEffect(() => {
    if (page === 1) {
      setNumbers(
        <div className="inline-flex items-center text-secondary-400 font-bold">
          <p className="px-2 py-2 text-primary-500 cursor-pointer">{page}</p>
          {page + 1 <= pageCount ? (
            <button
              type="button"
              className="px-2 py-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                nextPage(1);
              }}
            >
              {page + 1}
            </button>
          ) : null}

          {page + 2 <= pageCount ? (
            <button
              type="button"
              className="px-2 py-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                nextPage(2);
              }}
            >
              {page + 2}
            </button>
          ) : null}
        </div>
      );
    } else {
      setNumbers(
        <div className="inline-flex items-center text-secondary-400 font-bold">
          {page + 1 > pageCount && page > 2 ? (
            <button
              type="button"
              className="px-2 py-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                previousPage(2);
              }}
            >
              {page - 2}
            </button>
          ) : null}

          <button
            type="button"
            className="px-2 py-2 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              previousPage(1);
            }}
          >
            {page - 1}
          </button>

          <p className="px-2 py-2 text-primary-500 cursor-pointer">{page}</p>

          {page + 1 <= pageCount ? (
            <button
              type="button"
              className="px-2 py-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                nextPage(1);
              }}
            >
              {page + 1}
            </button>
          ) : null}
        </div>
      );
    }
  }, [page, pageCount, nextPage, previousPage]);

  return (
    <div className="flex items-center justify-center py-6">
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          previousPage(1);
        }}
        disabled={!hasPreviousPage}
        className="relative inline-flex items-center mx-2 my-2"
        aria-label="Previous"
      >
        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill="#7B7B7B"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {numbers}

      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          nextPage(1);
        }}
        disabled={!hasNextPage}
        className="relative inline-flex items-center mx-2 my-2"
        aria-label="Next"
      >
        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill="#7B7B7B"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
}
