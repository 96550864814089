import React from 'react';
import { useRouter } from 'next/router';
import { SearchIcon } from '../../icons';

export default function SearchBar() {
  const router = useRouter();
  const [search, setSearch] = React.useState('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    router.push('/search/[text]', `/search/${encodeURI(search)}`);
  };

  return (
    <form
      // className="h-1/2 w-3/12 xl:w-4/12 absolute left-1/2 hidden lg:flex border-secondary-300 border-b-2 transform -translate-x-1/2"
      className="h-9 sm:h-1/2 w-11/12 xs:w-8/12 sm:w-7/12 md:w-3/12 lg:w-4/12 xl:absolute xl:left-1/2 flex justify-between items-center border-secondary-300 border-b-2 transform xl:-translate-x-1/2 mt-4 md:mt-0"
      method="POST"
      onSubmit={onSubmit}
    >
      <input
        type="text"
        placeholder="Buscar..."
        name="search"
        className="h-full w-full border-none text-secondary-400 focus:outline-none focus-within:ring-0"
        onChange={(e) => {
          e.preventDefault();
          setSearch(e.target.value);
        }}
      />
      <button className="h-full w-6 pr-2 focus:outline-none" type="submit">
        <SearchIcon className="h-5" type="light" />
      </button>
    </form>
  );
}
