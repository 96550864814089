const DEV_ENDPOINT = `http://localhost:5555/graphql`;
const PROD_ENDPOINT = `https://api.bodebar.com.ve/graphql`;
export const ENDPOINT =
  process.env.NODE_ENV === 'development' ? DEV_ENDPOINT : PROD_ENDPOINT;

const DEV_ZELLE_ENDPOINT = `http://localhost:4444/api/v1/zelle`;
const PROD_ZELLE_ENDPOINT = `https://api.bodebar.com.ve/api/v1/zelle`;

export const ZELLE_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? DEV_ZELLE_ENDPOINT
    : PROD_ZELLE_ENDPOINT;

// Page config
export const PER_PAGE = 9;
export const PLACEHOLDER = '/img/placeholder.jpg';
