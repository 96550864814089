import React from 'react';
import { useActiveCurrency } from '../../../hooks';
import { formatMoney } from '../../../lib/formats';
import { VariantValue } from '../../../models';
import SoldOutSign from '../SoldOutSign';

interface VariantValueBoxProps {
  variantValue: VariantValue;
  selected: boolean;
  setSelectedVariant: React.Dispatch<React.SetStateAction<VariantValue>>;
}

export default function VariantValueBox({
  variantValue,
  selected,
  setSelectedVariant,
}: VariantValueBoxProps) {
  const [activeCurrency] = useActiveCurrency();
  const formatVariantTitle = (v1: string, v2: string, v3: string) => {
    const title =
      v1 + (v1 && v2 ? ` ${v2}` : v2) + ((v1 || v2) && v3 ? ` ${v3}` : v3);
    return title;
  };
  return (
    <button
      type="button"
      disabled={variantValue?.quantity === 0}
      className={`relative border pl-4 pr-6 py-1 mr-2 mb-2 cursor-pointer text-sm text-left transition-all ${
        selected ? 'bg-primary-100 border-primary-300' : 'hover:bg-gray-100'
      } `}
      onClick={(e) => {
        e.preventDefault();
        setSelectedVariant(variantValue);
      }}
    >
      <p className="font-semibold">
        {formatVariantTitle(
          variantValue?.value?.variant1,
          variantValue?.value?.variant2,
          variantValue?.value?.variant3
        )}
      </p>
      <p
        className={`${
          selected ? 'text-primary-400 font-bold' : 'text-secondary-400'
        }`}
      >
        {activeCurrency?.symbol}{' '}
        {formatMoney(variantValue?.price, activeCurrency?.symbol)}
      </p>
      {!variantValue?.quantity && <SoldOutSign />}
    </button>
  );
}
