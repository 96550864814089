import React from 'react';
import Link from 'next/link';
import nProgress from 'nprogress';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { ProductJsonLd } from 'next-seo';
import {
  ADD_ITEM_TO_SHOP_CART,
  DECREASE_ONE_ITEM_OF_CART,
  INCREASE_ONE_ITEM_OF_CART,
} from '../../graphql/mutations';
import { useRefetchShopCart, useShopCart } from '../../context/CartContext';
import { OrderProduct, Product, ShopCart, VariantValue } from '../../models';
import {
  useActiveCurrency,
  useUser,
  useNotify,
  useCurrency,
} from '../../hooks';
import { AddToCartIcon, AddIcon, RemoveIcon } from '../icons';
import { formatMoney } from '../../lib/formats';
import { PLACEHOLDER } from '../../config';

interface ProductCardProps {
  product: Product;
  variantValue: VariantValue;
  className?: string;
}

export default function ProductCard({
  product,
  variantValue,
  className = '',
}: ProductCardProps) {
  const router = useRouter();
  const notify = useNotify();
  const [user] = useUser();
  const [shopCart, setShopCart] = useShopCart();
  const [currencies] = useCurrency();
  const [activeCurrency, setActiveCurrency] = useActiveCurrency();
  const refetchShopCart = useRefetchShopCart();
  const [price, setPrice] = React.useState<number>(0);
  const [compareAtPrice, setCompareAtPrice] = React.useState<number>(0);
  const [item, setItem] = React.useState<OrderProduct>(null);
  const [units, setUnits] = React.useState<number>(variantValue?.quantity ?? 0);
  const [itemUnits, setItemUnits] = React.useState<number>(0);
  const [adding, setAdding] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [addingToCart, setAddingToCart] = React.useState(false);
  const [increaseItem] = useMutation(INCREASE_ONE_ITEM_OF_CART);
  const [decreaseItem] = useMutation(DECREASE_ONE_ITEM_OF_CART);
  const [addItemToCart] = useMutation<{ addItemToCart: ShopCart }>(
    ADD_ITEM_TO_SHOP_CART
  );

  React.useEffect(() => {
    const rate = activeCurrency?.rate ?? 0;
    setPrice(variantValue?.price * (rate / 100) ?? 0);
    setCompareAtPrice(variantValue?.compareAtPrice * (rate / 100) ?? 0);
  }, [activeCurrency, variantValue]);

  React.useEffect(() => {
    if (shopCart) {
      const _item = shopCart?.items?.find(
        (orderProduct) =>
          orderProduct?.product?._id === product?._id &&
          orderProduct?.variantValue?._id === variantValue?._id
      );
      setItem(_item ?? null);
      setItemUnits(_item?.quantity ?? 0);
    }
  }, [shopCart, product?._id, variantValue]);

  const addUnit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!deleting && !adding) {
      try {
        const order = {
          shopCartId: shopCart._id,
          orderProductId: item._id,
        };
        setAdding(true);
        nProgress.start();

        const { data } = await increaseItem({
          variables: {
            data: order,
          },
        });

        if (data.increaseOneItemOfCart) {
          setUnits(units + 1);
          setAdding(false);
          setShopCart(data.increaseOneItemOfCart);
          notify('¡Producto agregado!', 'success');
        }
      } catch (err) {
        notify(
          'El producto no posee unidades en el inventario actualmente',
          'danger'
        );
      } finally {
        nProgress.done();
      }
    }
  };
  const removeUnit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (units > 1 && !deleting && !adding) {
      try {
        const order = {
          shopCartId: shopCart._id,
          orderProductId: item._id,
        };
        setDeleting(true);
        nProgress.start();

        const { data } = await decreaseItem({
          variables: {
            data: order,
          },
        });

        if (data.decreaseOneItemOfCart) {
          setDeleting(false);
          setShopCart(data.decreaseOneItemOfCart);
        }
      } catch (err) {
        notify(
          'Debe haber al menos una unidad del producto en el carrito',
          'danger'
        );
      } finally {
        nProgress.done();
      }
    }
  };
  const onAddToCart = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!user) {
      router.push('/enter');
      return;
    }

    if (!addingToCart) {
      setAddingToCart(true);

      if (units > 0) {
        try {
          nProgress.start();
          const _item = {
            shopCartId: user.client.shopCart._id,
            variantValueId: variantValue._id,
            productId: product._id,
            quantity: 1,
          };
          const { data } = await addItemToCart({
            variables: {
              data: _item,
            },
          });

          if (data?.addItemToCart) {
            refetchShopCart();
            setAddingToCart(false);
            setUnits(units - 1);
            notify('¡Producto agregado!', 'success');
          }
        } catch (err) {
          notify(err.msg, 'danger');
        } finally {
          nProgress.done();
        }
      } else {
        notify(
          'El producto no posee unidades en el inventario actualmente',
          'danger'
        );
      }
    }
  };
  const onChangeCurrency = (e) => {
    e.preventDefault();
    let changed = false;
    currencies.forEach((currency) => {
      if (currency.slug !== activeCurrency.slug && !changed) {
        setActiveCurrency(currency);
        changed = true;
      }
    });
  };

  return (
    <>
      <ProductJsonLd
        productName={product?.title}
        images={product?.photos}
        description={product?.description}
        brand={product?.brand?.name}
      />
      <article
        className={`w-full p-5 relative bg-white max-w-sm align-top ${className} hover:shadow-md`}
      >
        <div className="flex flex-col">
          {/* Image */}
          <div className="h-64 overflow-hidden flex items-center justify-center cursor-pointer">
            <Link
              href="/product/[slug]"
              as={`/product/${product?._id}`}
              passHref
            >
              <img
                className="h-64"
                alt={`${product?.title}`}
                src={variantValue?.photo || product?.photos[0] || PLACEHOLDER}
              />
            </Link>
          </div>
          {/* Info */}
          <div className="w-full flex flex-col items-center h-auto px-4 pt-4">
            <div className="flex flex-col text-center w-60 font-bold mb-2">
              <h1 className="text-gray-800 text-center text-base">
                {product?.title}
              </h1>
              {variantValue?.value?.variant1 !== 'N/A' && (
                <p className="text-gray-500 text-center text-sm">
                  {variantValue?.value?.variant1}{' '}
                  {variantValue?.value?.variant2}{' '}
                  {variantValue?.value?.variant3}
                </p>
              )}
            </div>

            {compareAtPrice ? (
              <p
                className={`text-base text-center leading-normal text-secondary-400 mt-1 ${
                  compareAtPrice ? 'line-through' : 'mb-2'
                }`}
              >
                {`${activeCurrency?.symbol} ${formatMoney(
                  compareAtPrice,
                  activeCurrency?.symbol
                )}`}
              </p>
            ) : null}
            <p className="text-base text-center leading-normal text-primary-400 font-bold mb-2">
              {`${activeCurrency?.symbol} ${formatMoney(
                price,
                activeCurrency?.symbol
              )}`}
            </p>
          </div>
          {/* Buttons */}
          {item ? (
            <div className="h-auto flex justify-center px-4">
              <div className="flex h-7 w-24">
                <button
                  type="button"
                  aria-label="remove"
                  className="h-full w-1/3 border-2 border-secondary-200 flex justify-center items-center rounded-l focus:outline-none focus-within:ring-0"
                  onClick={removeUnit}
                >
                  <RemoveIcon className="h-5" type="dark" />
                </button>

                <div className="h-full w-1/3 border-t-2 border-b-2 border-secondary-200 flex justify-center items-center text-sm text-secondary-500 font-bold">
                  {itemUnits}
                </div>

                <button
                  type="button"
                  aria-label="add"
                  className="h-full w-1/3 border-2 border-secondary-200 flex justify-center items-center rounded-r focus:outline-none focus-within:ring-0"
                  onClick={addUnit}
                >
                  <AddIcon className="h-5" type="dark" />
                </button>
              </div>
            </div>
          ) : (
            <div className="h-auto flex justify-center px-4">
              <button
                className="h-7 w-7 cursor-pointer text-xl text-secondary-400 focus:outline-none focus-within:ring-0"
                type="button"
                aria-label="currency product"
                onClick={onChangeCurrency}
              >
                <p>{activeCurrency?.symbol}</p>
              </button>
              <button
                type="button"
                aria-label="add to cart"
                className="h-7 w-7 flex justify-center items-center ml-3 focus:outline-none focus-within:ring-0"
                onClick={onAddToCart}
              >
                <AddToCartIcon
                  className="cursor-pointer h-5 focus:outline-none focus-within:ring-0"
                  type="gray"
                />
              </button>
            </div>
          )}
        </div>
      </article>
    </>
  );
}
