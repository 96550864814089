import React from 'react';
import Link from 'next/link';
import { useUser } from '../../hooks';
import { MenuIcon } from '../icons';

const ProfileSidebar = () => {
  const [active, setActive] = React.useState(false);
  const [user] = useUser();

  const onClick = () => {
    setActive(!active);
  };

  return (
    <div
      className={`absolute top-0 left-0 bottom-0 z-10 sm:h-full sm:w-72 ${
        active ? 'w-1/2 px-7 pt-16' : 'h-10 w-16 pt-5'
      } sm:px-5 lg:px-14 sm:pt-20 bg-secondary-500 text-secondary-100 tracking-wide uppercase font-semibold`}
    >
      <div>
        <button
          type="button"
          className="absolute top-0 left-0 h-10 w-full text-center hover:bg-secondary-400 flex justify-center items-center focus:outline-none focus-within:ring-0 sm:hidden"
          onClick={onClick}
        >
          <MenuIcon className="h-8" type="white" />
        </button>
      </div>
      <div
        className={`w-full h-full relative ${
          active ? 'block' : 'hidden'
        } sm:block`}
      >
        <p
          className="text-lg md:text-2xl pb-20 text-secondary-100"
          style={{ borderBottom: '1px solid #4A4B53' }}
        >
          Bienvenido, {user.name.split(' ')[0]}
        </p>

        {/* Options */}
        <div className="pb-8 justify-between">
          <Link href="/profile" passHref>
            <div
              className="w-full cursor-pointer p-3 flex items-center text-sm"
              style={{ borderBottom: '1px solid #4A4B53' }}
            >
              <MenuIcon className="h-5" type="white" />
              <p className="pl-2">Perfil</p>
            </div>
          </Link>
          <Link href="/profile/my-orders" passHref>
            <div
              className="w-full cursor-pointer p-3 flex items-center text-sm"
              style={{ borderBottom: '1px solid #4A4B53' }}
            >
              <MenuIcon className="h-5" type="white" />
              <p className="pl-2">Mis ordenes</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileSidebar;
