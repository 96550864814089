import { VolumeSale } from '../models';

export function volumeDiscounts(
  units: number,
  unitPrice: number,
  volumeSales: VolumeSale[]
): number {
  const activeVolume = volumeSales.reduce((current, next) => {
    const cVol = current?.volume ?? 0;
    const nVol = next?.volume ?? 0;
    // Next volume applies and current doesn't
    if (nVol <= units && cVol > units) return next;
    // Current volume applies and next doesn't
    if (cVol <= units && nVol > units) return current;
    // None of them applies
    if (cVol > units && cVol > units) return null;
    // Both apply and the greater volume is return
    return cVol > nVol ? current : next;
  }, null);
  if (!activeVolume) return units * unitPrice;
  const { volume, discount, discountType } = activeVolume;
  const volumeDiscount = (unitPrice * volume * discount) / 100;
  const totalDiscount = discountType
    ? volumeDiscount * Math.trunc(units / volume)
    : volumeDiscount;

  return units * unitPrice - totalDiscount;
}
