import React from 'react';
import { getTailwindColor } from '../../lib/iconHelper';

interface InstagramIconsProps {
  className?: string;
  type?: string;
}
export default function InstagramIcon({
  className,
  type,
}: InstagramIconsProps) {
  const tailwindColor = getTailwindColor(type);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      className={`fill-current ${tailwindColor} ${className}`}
    >
      <path
        fill="#FBFBFB"
        d="M.192 10.12V4.2l.024-.246c.043-.411.155-.812.33-1.186A3.842 3.842 0 012.356.918 3.665 3.665 0 013.619.551l.255-.024h5.903a.34.34 0 00.045.007c.263.013.525.054.78.123a3.862 3.862 0 012.852 3.668c.013 1.882.005 3.764.003 5.646a3.759 3.759 0 01-.17 1.105 3.864 3.864 0 01-3.675 2.716H4.078a4.87 4.87 0 01-.368-.015 3.674 3.674 0 01-1.255-.328 3.843 3.843 0 01-1.794-1.67 3.729 3.729 0 01-.445-1.416l-.024-.243zm6.633-8.575H4.101c-.116 0-.233.004-.35.015a2.595 2.595 0 00-1.118.357 2.756 2.756 0 00-1.42 2.475c-.01 1.844-.003 3.687-.003 5.53a2.812 2.812 0 002.8 2.845c1.857.008 3.714 0 5.571 0 .167 0 .334-.013.499-.04.517-.08.999-.307 1.388-.656a2.773 2.773 0 00.966-2.153c.003-1.841.003-3.683 0-5.525a2.82 2.82 0 00-2.797-2.846c-.935-.008-1.874 0-2.813 0l.001-.002z"
      />
      <path
        fill="#FBFBFB"
        d="M6.83 3.588a3.572 3.572 0 11-3.577 3.57 3.58 3.58 0 013.576-3.57zm0 1.02a2.552 2.552 0 102.356 1.573 2.545 2.545 0 00-2.361-1.573h.004zM10.654 2.568a.766.766 0 11-.005 1.531.766.766 0 01.005-1.531z"
      />
    </svg>
  );
}
