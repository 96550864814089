// ------------------------------  FORMAT TITLE  -------------------------------
export function formatTitle(title: string): string {
  const words = title?.split(' ');
  if (words?.length === 1) return firstLetterUpperCase(title);
  return words
    .reduce((acum, word, index) => {
      if (word) {
        if (index === 1)
          return `${firstLetterUpperCase(acum)} ${firstLetterUpperCase(word)}`;
        if (word?.toLowerCase() === 'ml') return `${acum} ml`;
        return `${acum} ${firstLetterUpperCase(word)}`;
      }
      return acum;
    })
    .trim();
}

function firstLetterUpperCase(word: string): string {
  return `${word[0]?.toUpperCase()}${word?.slice(1)?.toLowerCase()}`;
}

// ------------------------------  ORDER STATUS  -------------------------------
export function formatOrderStatus(status: number): string {
  // # 0 es por pagar
  // # 1 en check administrativo
  // # 2 pagado
  // # 3 entregado
  // # 4 crédito
  // # 5 crédito entregado
  // # 6 crédito pagado
  // # 7 Anulada
  switch (status) {
    case 0:
      return 'Por pagar';
    case 1:
      return 'Revisión Administrativa';
    case 2:
      return 'Pagado';
    case 3:
      return 'Entregado';
    case 4:
      return 'Crédito';
    case 5:
      return 'Crédito Entregado';
    case 6:
      return 'Crédito Pagado';
    case 7:
      return 'Anulada';

    default:
      return 'No definido';
  }
}

// ---------------------------------  MONEY  -----------------------------------
export function formatMoney(
  candidate: number | string | null | undefined,
  currencySymbol: string,
  decimalPoint = true
): string {
  if (!candidate && candidate !== 0) return '-';
  if (Number.isNaN(Number(candidate))) return '-';
  const numberFormat = new Intl.NumberFormat(
    currencySymbol === '$' ? 'en-US' : 'de-DE',
    {
      style: 'currency',
      currency: 'USD',
    }
  );
  const result = numberFormat
    .format(Number(candidate) / 100)
    .replace('$', '')
    .replace(/( )/g, '')
    .trim();

  if (decimalPoint) {
    return result;
  }
  const [integer, decimal] = result.split('.');
  return `${integer.replace(/(,)/g, '.')},${decimal}`;
}
