/* eslint-disable react/no-danger */
import React from 'react';
import { TermsOfService } from '../models';
import PageHeader from './shared/PageHeader';

interface TermsOfServiceProps {
  termsOfService: TermsOfService;
}

export default function TermsOfServiceView({
  termsOfService,
}: TermsOfServiceProps) {
  return (
    <div className="w-full">
      <PageHeader title="Términos y condiciones" />
      <div className="w-full px-10 sm:px-28 py-16">
        <div
          className="w-full px-10 py-7 border-4 border-primary-400 rounded-lg"
          dangerouslySetInnerHTML={{
            __html: termsOfService?.text ?? '',
          }}
        />
      </div>
    </div>
  );
}
