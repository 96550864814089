const directions = [
  {
    _id: 9,
    state: 'Distrito Capital',
    municipalities: ['Libertador'],
  },
  {
    _id: 15,
    state: 'Miranda',
    municipalities: [
      'Acevedo',
      'Andrés Bello',
      'Baruta',
      'Brión',
      'Bolívar',
      'Buroz',
      'Carrizal',
      'Chacao',
      'Cristóbal Rojas',
      'El Hatillo',
      'Guaicaipuro',
      'Gual',
      'Independencia',
      'Lander',
      'Los Salias',
      'Páez',
      'Paz Castillo',
      'Plaza',
      'Sucre',
      'Urdaneta',
      'Zamora',
    ],
  },
];

export default directions;
