import React from 'react';
import { Product, VariantValue } from '../../../models';
import Pagination from '../Pagination';
import ProductCard from '../ProductCard';

interface CatalogProps {
  items: { variantValue: VariantValue; product: Product }[];
  pageInfo: {
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    pageCount?: number;
    itemCount?: number;
  };
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export default function Catalog({
  items,
  pageInfo,
  page,
  setPage,
}: CatalogProps) {
  const nextPage = (num: number) => {
    setPage(page + num);
  };
  const previousPage = (num: number) => {
    setPage(page - num);
  };

  return (
    <>
      {items.length > 0 ? (
        <div className="w-full px-10">
          <div className="w-full flex flex-col items-center lg:grid lg:auto-rows-max lg:grid-cols-2 gap-5 xl:grid-cols-3 xl:grid-rows-3">
            {items?.map((item) => (
              <ProductCard key={item?.variantValue?._id} {...item} />
            ))}
          </div>
          <Pagination
            hasNextPage={pageInfo.hasNextPage}
            hasPreviousPage={pageInfo.hasPreviousPage}
            pageCount={pageInfo.pageCount}
            page={page}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <p className="pb-5 uppercase text-secondary-400 font-semibold">
            Disculpe, no se encontraron productos
          </p>
          <img alt="" className="h-52" src="/img/empty-cart.png" />
        </div>
      )}
    </>
  );
}
