import gql from 'graphql-tag';

// Product
export const GET_PRODUCT = gql`
  query GET_PRODUCT($filter: FilterGetProductInput!) {
    getProduct(filter: $filter) {
      suggestions {
        product {
          _id
          slug
          title
          photos
        }
        variantValue {
          _id
          price
          compareAtPrice
          photo
          quantity
          value {
            variant1
            variant2
            variant3
          }
          location {
            _id
          }
        }
      }
      product {
        _id
        title
        description
        dataSheet
        photos
        price
        rating
        compareAtPrice
        volatileInventory
        brand {
          name
        }
        comments {
          _id
          text
          rating
          client {
            name
          }
        }
        variants {
          title
          tags
        }
        variantValues {
          _id
          value {
            variant1
            variant2
            variant3
          }
          location {
            _id
          }
          disabled
          price
          compareAtPrice
          quantity
          photo
        }
        categories {
          slug
          name
        }
        extraInfo {
          name
          value
        }
        volumeSales {
          name
          active
          volume
          discount
          discountType
        }
      }
    }
  }
`;

export const GET_PRODUCTS_LIST = gql`
  query GET_PRODUCTS_LIST($filter: FilterFindManyProductInput) {
    products(filter: $filter) {
      slug
      title
      description
      isService
      photos
      createdAt
      _id
      categories {
        name
        slug
        _id
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyProductInput!
  ) {
    productPagination(page: $page, perPage: $perPage, filter: $filter) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        itemCount
      }
      items {
        slug
        title
        description
        isService
        photos
        _id
        priority
        brand {
          name
        }
        variantValues {
          value {
            variant1
            variant2
            variant3
          }
          disabled
          price
          compareAtPrice
          quantity
          _id
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_CATEGORY_BRAND = gql`
  query GET_PRODUCT_BY_CATEGORY_BRAND(
    $categorySlug: String!
    $brandSlug: String!
  ) {
    getProductByCategoryBrand(
      categorySlug: $categorySlug
      brandSlug: $brandSlug
    ) {
      slug
      title
      description
      isService
      photos
      _id
      variantValues {
        price
      }
      categories {
        name
        slug
      }
    }
  }
`;

export const SEARCH_PRODUCT_BY_CATEGORIES = gql`
  query SEARCH_PRODUCT_BY_CATEGORIES($data: SearchProductByCategoriesInput!) {
    searchProductByCategories(data: $data) {
      _id
      slug
      title
      description
      isService
      photos
      variantValues {
        price
      }
    }
  }
`;

export const GET_PRODUCTS_PAGINATION = gql`
  query GET_PRODUCTS_PAGINATION(
    $data: ProductPaginationByCategoriesInput! # $slugs: [String!]!
  ) {
    # getBrandsByCategories(slugs: $slugs) {
    #   _id
    #   slug
    #   name
    # }
    productPaginationByCategories(data: $data) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        itemCount
      }
      items {
        slug
        title
        description
        isService
        photos
        _id
        price
        tags
        variantValues {
          value {
            variant1
            variant2
            variant3
          }
          price
          compareAtPrice
          quantity
          _id
        }
      }
    }
  }
`;

export const SEARCH_BY_CATEGORY_PAGINATION = gql`
  query SEARCH_BY_CATEGORY_PAGINATION(
    $data: SearchProductByCategoriesPaginationInput!
    $slugs: [String!]!
  ) {
    getBrandsByCategories(slugs: $slugs) {
      _id
      slug
      name
    }
    searchProductByCategoriesPagination(data: $data) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        itemCount
      }
      items {
        slug
        title
        description
        isService
        photos
        _id
        tags
        variantValues {
          price
        }
        categories {
          name
          slug
        }
      }
    }
  }
`;

export const SEARCH_PRODUCT_PAGINATION = gql`
  query SEARCH_PRODUCT_PAGINATION($data: SearchProductPaginationInput) {
    searchProductPagination(data: $data) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        itemCount
      }
      items {
        product {
          _id
          slug
          title
          photos
        }
        variantValue {
          _id
          price
          compareAtPrice
          quantity
          photo
          value {
            variant1
            variant2
            variant3
          }
        }
      }
    }
  }
`;

export const HOME_PRODUCTS = gql`
  query HOME_PRODUCTS($data: HomeProductsInput!) {
    homeProducts(data: $data) {
      items {
        product {
          _id
          slug
          title
          photos
        }
        variantValue {
          _id
          photo
          price
          compareAtPrice
          quantity
          location {
            _id
          }
          value {
            variant1
            variant2
            variant3
          }
        }
      }
    }
  }
`;

export const BEST_SELLERS = gql`
  query BEST_SELLERS {
    bestSellers {
      slug
      title
      isService
      photos
      _id
      tags
      variantValues {
        _id
        price
        compareAtPrice
        quantity
      }
    }
  }
`;

export const FILTER_PRODUCTS_PAGINATION = gql`
  query FILTER_PRODUCTS_PAGINATION($data: FilterProductsPaginationInput!) {
    filterProductsPagination(data: $data) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        itemCount
      }
      items {
        product {
          _id
          slug
          title
          description
          isService
          photos
          priority
          tags
          brand {
            name
          }
        }
        variantValue {
          _id
          price
          photo
          compareAtPrice
          quantity
          value {
            variant1
            variant2
            variant3
          }
        }
      }
      brands {
        _id
        name
      }
    }
  }
`;

export const GET_SALES = gql`
  query GET_SALES($data: ProductPaginationSalesInput!) {
    productPaginationSales(data: $data) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        itemCount
      }
      items {
        slug
        title
        description
        isService
        photos
        _id
        variantValues {
          price
          compareAtPrice
        }
      }
    }
  }
`;

export const GET_QUESTIONS = gql`
  query GET_QUESTIONS {
    questions {
      _id
      title
      text
      createdAt
    }
  }
`;

// Config and user
export const CURRENT_CONFIG = gql`
  query CURRENT_CONFIG {
    currentSetting {
      carouselImages {
        title
        description
        url
      }
      loginImages {
        title
        description
        url
      }
      banner {
        text
        active
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      _id
      name
      email
      slug
      active
      privilege
      dni
      dniType
      client {
        _id
        phone
        shopCart {
          _id
          items {
            _id
            variant1
            variant2
            variant3
            quantity
            price
            photo
            product {
              _id
              title
              description
              slug
              brand {
                name
                slug
              }
              categories {
                name
                slug
              }
            }
            variantValue {
              _id
            }
            volumeSales {
              discount
              discountType
              volume
            }
            taxes {
              name
              value
            }
          }
          createdAt
          updatedAt
        }
        orders {
          _id
          status
          paid
          code
          subtotal
          total
          products {
            title
            quantity
            price
          }
          location {
            name
          }
          createdAt
          charges {
            ref
            amount
            bank
            method
          }
        }
        address {
          _id
          country
          state
          municipality
          street
        }
        promos {
          _id
          name
          code
        }
      }
    }
  }
`;

export const GET_PLUGINS = gql`
  query GET_PLUGINS {
    plugins {
      _id
      name
      active
      credentials {
        name
        value
        type
      }
    }
  }
`;

export const GET_PLUGIN = gql`
  query GET_PLUGIN($filter: FilterFindOnePluginInput) {
    plugin(filter: $filter) {
      _id
      name
      active
      credentials {
        name
        value
        type
      }
    }
  }
`;

// Shopcart
export const GET_SHOP_CART_BY_ID = gql`
  query GET_SHOP_CART_BY_ID($id: MongoID!) {
    shopCartById(_id: $id) {
      client {
        user {
          name
        }
      }
      items {
        product {
          title
          description
          _id
        }
        variantValue {
          _id
        }
        volumeSales {
          discount
          discountType
          volume
        }
        taxes {
          name
          value
        }
      }
      createdAt
    }
  }
`;

export const GET_SHOP_CART = gql`
  query GET_SHOP_CART($filter: FilterFindOneShopCartInput!) {
    shopCart(filter: $filter) {
      _id
      items {
        _id
        quantity
        price
        photo
        variant1
        variant2
        variant3
        product {
          title
          description
          categories {
            name
            slug
          }
        }
        variantValue {
          _id
        }
        volumeSales {
          discount
          discountType
          volume
        }
        taxes {
          name
          value
        }
      }
      createdAt
      updatedAt
    }
  }
`;

// Category
export const GET_CATEGORIES = gql`
  query GET_CATEGORIES {
    categories(filter: { active: true }) {
      _id
      name
    }
  }
`;

// Brand
export const GET_BRANDS = gql`
  query GET_BRANDS($skip: Int, $limit: Int) {
    brands(skip: $skip, limit: $limit) {
      slug
      name
      _id
    }
  }
`;

export const SEARCH_BY_BRAND = gql`
  query SEARCH_BY_BRAND($brandSlug: String!) {
    searchByBrand(brandSlug: $brandSlug) {
      slug
      title
      description
      isService
      photos
      _id
      variantValues {
        price
      }
      categories {
        name
        slug
      }
    }
  }
`;

export const SEARCH_BY_CATEGORY = gql`
  query SEARCH_BY_CATEGORY($categorySlug: String!) {
    getBrandsByCategory(categorySlug: $categorySlug) {
      name
      slug
    }
    searchByCategory(categorySlug: $categorySlug) {
      slug
      title
      description
      isService
      photos
      _id
      variantValues {
        price
      }
      categories {
        name
        slug
      }
    }
  }
`;

// Currency
export const GET_CURRENCY = gql`
  query GET_CURRENCY {
    currencies {
      slug
      name
      symbol
      rate
    }
  }
`;

export const SEARCH_PRODS = gql`
  query SEARCH_PRODS($searchString: String!) {
    searchProduct(searchString: $searchString) {
      slug
      title
      description
      isService
      photos
      _id
      variantValues {
        price
      }
    }
  }
`;

// Location
export const GET_LOCATIONS = gql`
  query GET_LOCATIONS {
    locations(filter: { active: true }) {
      _id
      name
      shippingOptions {
        _id
        name
        price
      }
    }
  }
`;

export const GET_CHECKOUT_DATA = gql`
  query GET_CHECKOUT_DATA($filter: FilterFindOneLocationInput!) {
    currencies {
      _id
      name
      symbol
      rate
    }
    location(filter: $filter) {
      shippingOptions {
        _id
        name
        price
      }
    }
    paymentMethods {
      _id
      title
      userData {
        _id
        name
        value
      }
      currency {
        _id
      }
    }
    shippings {
      _id
      name
      price
    }
  }
`;

export const SEARCH_CATEGORIES_BY_CATEGORY = gql`
  query SEARCH_CATEGORIES_BY_CATEGORY($data: SearchCategoriesByCategoryInput!) {
    searchCategoriesByCategory(data: $data) {
      data {
        category {
          _id
          name
          slug
        }
        products {
          _id
          slug
          title
          description
          isService
          photos
          variantValues {
            price
          }
        }
      }
    }
  }
`;

// Promo code
export const GET_PROMO_CODE = gql`
  query GET_PROMO_CODE($filter: FilterFindOnePromoCodeInput!) {
    promoCode(filter: $filter) {
      _id
      discount
      fixed
      percentage
      expirationDate
      code
    }
  }
`;

// Order
export const GET_ORDER = gql`
  query GET_ORDER($filter: FilterFindOneOrderInput!) {
    order(filter: $filter) {
      _id
      status
      code
      subtotal
      taxes {
        name
        value
      }
      extraFees
      discount
      total
      phone
      client {
        name
        email
      }
      shipping {
        name
        price
      }
      charges {
        ref
        bank
        method
        amount
        createdAt
        _id
      }
      location {
        name
      }
      products {
        _id
        title
        quantity
        price
        title
        photo
        variant1
        variant2
        variant3
        volumeSales {
          discount
          discountType
          volume
        }
      }
    }
  }
`;

// eCommerce Info
export const GET_TERMS_OF_SERVICE = gql`
  query GET_TERMS_OF_SERVICE {
    termsOfService {
      _id
      text
    }
  }
`;

export const GET_ABOUT_US = gql`
  query GET_ABOUT_US {
    aboutUs {
      _id
      text
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS {
    paymentMethods {
      _id
      title
      userData {
        _id
        name
        value
      }
      currency {
        _id
        name
      }
    }
  }
`;

export const GET_LOCATION_AND_SHIPPINGS = gql`
  query GET_CREATE_LOCATION_AND_SHIPPINGS {
    locations(filter: { active: true }) {
      _id
      name
    }
    shippings {
      _id
      name
      price
    }
  }
`;
