/* eslint-disable react/no-danger */
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { ProductJsonLd } from 'next-seo';
import { Product, ShopCart, VariantValue } from '../../../models';
import { ADD_ITEM_TO_SHOP_CART } from '../../../graphql/mutations';
import { useRefetchShopCart, useShopCart } from '../../../context/CartContext';
import { formatMoney } from '../../../lib/formats';
import {
  useNotify,
  useActiveCurrency,
  useLocationID,
  useUser,
} from '../../../hooks';
import { PLACEHOLDER } from '../../../config';
import CommentSection from './CommentSection';
import Suggestions from './Suggestions';
import VariantValueBox from './VariantValueBox';
import VolumeSaleBox from './VolumeSaleBox';
import RemoveIcon from '../../icons/RemoveIcon';
import AddIcon from '../../icons/AddIcon';

interface ProductProfileProps {
  product: Product;
  suggestions: { product: Product; variantValue: VariantValue }[];
}

export default function ProductProfile({
  product,
  suggestions,
}: ProductProfileProps) {
  const router = useRouter();
  const notify = useNotify();
  const [user] = useUser();
  const [shopCart] = useShopCart();
  const [activeCurrency] = useActiveCurrency();
  const locationId = useLocationID();
  // Render hooks
  const [infoOption, setInfoOption] = React.useState('descripcion');
  const [units, setUnits] = React.useState(1);
  const [price, setPrice] = React.useState(0);
  const [compareAtPrice, setCompareAtPrice] = React.useState(0);
  const [variantValues, setVariantValues] = React.useState<VariantValue[]>([]);
  const [selectedVariant, setSelectedVariant] =
    React.useState<VariantValue>(null);
  const [activePhoto, setActivePhoto] = React.useState(
    product?.photos[0] || PLACEHOLDER
  );
  const [availableUnits, setAvailableUnits] = React.useState(0);
  // ShopCart hooks
  const [addingToCart, setAddingToCart] = React.useState(false);
  const refetchShopCart = useRefetchShopCart();
  const [addItemToCart] = useMutation<{ addItemToCart: ShopCart }>(
    ADD_ITEM_TO_SHOP_CART
  );

  React.useEffect(
    function syncProductVariants() {
      if (product?.variantValues?.length) {
        const _variantValues = product?.variantValues?.filter(
          (vv) => !vv?.disabled && vv?.location?._id === locationId
        );
        setVariantValues(_variantValues);
        setSelectedVariant(_variantValues?.find((vv) => vv?.quantity));
      }
    },
    [product, locationId]
  );
  React.useEffect(
    function syncSelectedVariantData() {
      setAvailableUnits(selectedVariant?.quantity ?? 0);
      setPrice(selectedVariant?.price ?? 0);
      setCompareAtPrice(selectedVariant?.compareAtPrice ?? 0);
      if (selectedVariant?.photo) setActivePhoto(selectedVariant?.photo);
      else setActivePhoto(product?.photos[0] || PLACEHOLDER);
    },
    [selectedVariant, product?.photos]
  );

  const onAddToCart = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!user) {
      router.push('/enter');
      return;
    }

    if (!addingToCart) {
      try {
        const item = {
          shopCartId: shopCart._id,
          variantValueId: selectedVariant._id,
          productId: product._id,
          quantity: units,
        };
        setAddingToCart(true);

        const { data } = await addItemToCart({
          variables: {
            data: item,
          },
        });

        if (data.addItemToCart) {
          notify('¡Producto agregado!', 'success');
          const newUnits = availableUnits - units;
          setAvailableUnits(newUnits);
          const _variantValues = variantValues.map((vv) => ({ ...vv }));
          const _selectedVariant = _variantValues.find(
            (vv) => vv?._id === selectedVariant?._id
          );
          _selectedVariant.quantity = newUnits;
          setVariantValues(_variantValues);
          if (newUnits < units) {
            setUnits(newUnits);
          }

          refetchShopCart();
        }
      } catch (err) {
        console.log(err);
        notify(err.message, 'danger');
        setUnits(availableUnits);
      } finally {
        setAddingToCart(false);
      }
    }
  };
  const addUnit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (availableUnits > units) setUnits(units + 1);
  };
  const removeUnit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (units > 1) setUnits(units - 1);
  };

  const changeInfo = (option: string) => {
    if (option !== infoOption) {
      setInfoOption(option);
    }
  };

  return (
    <div className="h-auto w-full px-10 py-5 sm:px-20 md:px-28 lg:px-36 sm:py-20">
      {/* history link */}
      <div className="hidden sm:flex uppercase tracking-wide text-secondary-400 font-bold text-xs lg:text-sm">
        <Link href="/">
          <a>inicio /</a>
        </Link>

        <Link href="/products">
          <a className="ml-2">productos /</a>
        </Link>

        <p className="text-primary-400 ml-2">{product?.title}</p>
      </div>

      {/* product info */}
      <div className="h-auto sm:h-72 lg:h-96 w-full mt-16 flex flex-col items-center sm:flex-row">
        <div className="sm:w-1/2 h-auto flex justify-center items-center">
          <div className="h-72 w-72 md:h-80 md:w-80 lg:h-96 lg:w-96 mb-4 sm:mb-0 flex justify-center items-center">
            <img
              className="h-full"
              alt={`${product?.title}`}
              src={activePhoto || PLACEHOLDER}
            />
          </div>
        </div>
        <div className="w-full sm:w-1/2 h-auto flex flex-col justify-between items-center sm:items-start sm:pl-5">
          <div>
            {/* title */}
            <h1 className="font-extrabold text-secondary-500 tracking-wider text-xl lg:text-2xl">
              {product?.title}
            </h1>
            {/* price */}
            {compareAtPrice ? (
              <p className="leading-normal mt-2 lg:mt-5 line-through text-secondary-400 text-sm lg:text-lg">
                {`${activeCurrency?.symbol} ${formatMoney(
                  compareAtPrice * (activeCurrency?.rate / 100),
                  activeCurrency?.symbol
                )}`}
              </p>
            ) : null}
            <p
              className={`leading-normal text-base lg:text-xl text-primary-500 font-bold mb-2 ${
                !compareAtPrice && 'lg:mt-5'
              }`}
            >
              {`${activeCurrency?.symbol} ${formatMoney(
                price * (activeCurrency?.rate / 100),
                activeCurrency?.symbol
              )}`}
            </p>

            {/* variant values */}
            {variantValues?.length > 1 && (
              <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3">
                {variantValues?.map((variant) => (
                  <VariantValueBox
                    key={variant?._id}
                    variantValue={variant}
                    selected={variant?._id === selectedVariant?._id}
                    setSelectedVariant={setSelectedVariant}
                  />
                ))}
              </div>
            )}
            {product?.volumeSales?.length ? (
              <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-2 mt-2 mb-4">
                {product?.volumeSales?.map((vs) =>
                  vs?.active ? (
                    <VolumeSaleBox
                      volumeSale={vs}
                      units={availableUnits}
                      unitPrice={price}
                      setUnits={setUnits}
                    />
                  ) : null
                )}
              </div>
            ) : null}
          </div>
          <div className="mt-5 sm:mt-0 flex flex-col items-center sm:items-start">
            <div className="flex h-9 w-28">
              <button
                type="button"
                aria-label="remove"
                className="h-full w-1/3 border-2 border-secondary-200 flex justify-center items-center rounded-l focus:outline-none focus-within:ring-0"
                onClick={removeUnit}
              >
                <RemoveIcon className="h-5" type="dark" />
              </button>

              <div className="h-full w-1/3 border-t-2 border-b-2 border-secondary-200 flex justify-center items-center text-sm text-secondary-500 font-bold">
                {units}
              </div>

              <button
                type="button"
                aria-label="add"
                className="h-full w-1/3 border-2 border-secondary-200 flex justify-center items-center rounded-r focus:outline-none focus-within:ring-0"
                onClick={addUnit}
              >
                <AddIcon className="h-5" type="dark" />
              </button>
            </div>
            {availableUnits > 0 ? (
              <button
                type="button"
                className="h-10 w-36 uppercase text-sm tracking-wider text-white bg-primary-500 rounded-md mt-3 sm:mt-20"
                style={{
                  filter: 'drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25))',
                }}
                onClick={onAddToCart}
              >
                Agregar
              </button>
            ) : (
              <button
                type="button"
                className="h-10 w-36 uppercase text-sm tracking-wider text-white bg-secondary-400 rounded-md mt-3 sm:mt-20"
                style={{
                  filter: 'drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25))',
                }}
              >
                Agregar
              </button>
            )}
          </div>
        </div>
      </div>

      {/* extra images */}
      <div
        className="inline-flex mt-10 sm:mt-20"
        style={{ boxShadow: '3px 3px 6px rgba(51, 51, 51, 0.25)' }}
      >
        {product?.photos?.map((photo, index) => (
          <button
            type="button"
            className="h-16 w-16 sm:h-24 sm:w-24 border-secondary-200 border flex justify-center items-center cursor-pointer focus:outline-none focus-within:ring-0"
            onClick={(e) => {
              e.preventDefault();
              setActivePhoto(photo);
            }}
            key={index}
          >
            <img className="h-12" alt={`${product?.title}`} src={photo} />
          </button>
        ))}
      </div>

      {/* extra info */}
      <div className="h-auto w-full mt-20">
        <div className="mb-10 flex uppercase justify-evenly text-center font-bold tracking-wide text-secondary-400 text-xs sm:text-base">
          <button
            type="button"
            className={`
              w-1/3 cursor-pointer border-b-4 focus:outline-none focus-within:ring-0
              ${
                infoOption === 'descripcion'
                  ? 'border-primary-400 text-primary-400 font-semibold'
                  : 'border-secondary-400'
              }
            `}
            onClick={(e) => {
              e.preventDefault();
              changeInfo('descripcion');
            }}
          >
            Descripción
          </button>
          <button
            type="button"
            className={`
              w-1/3 cursor-pointer border-b-4 focus:outline-none focus-within:ring-0
              ${
                infoOption === 'ficha-tecnica'
                  ? 'border-primary-400 text-primary-400 font-semibold'
                  : 'border-secondary-400'
              }
            `}
            onClick={(e) => {
              e.preventDefault();
              changeInfo('ficha-tecnica');
            }}
          >
            Ficha Técnica
          </button>
          <button
            type="button"
            className={`
              w-1/3 cursor-pointer border-b-4 focus:outline-none focus-within:ring-0
              ${
                infoOption === 'comentarios'
                  ? 'border-primary-400 text-primary-400 font-semibold'
                  : 'border-secondary-400'
              }
            `}
            onClick={(e) => {
              e.preventDefault();
              changeInfo('comentarios');
            }}
          >
            Comentarios
          </button>
        </div>
        <div className="h-auto overflow-hidden">
          {infoOption === 'descripcion' ? (
            <div
              className="w-full"
              dangerouslySetInnerHTML={{
                __html: product?.description ?? '',
              }}
            />
          ) : null}
          {infoOption === 'ficha-tecnica' ? (
            <div
              className="w-full"
              dangerouslySetInnerHTML={{
                __html: product?.dataSheet ?? '',
              }}
            />
          ) : null}
          {infoOption === 'comentarios' ? (
            <CommentSection
              comments={product.comments}
              productId={product._id}
            />
          ) : null}
        </div>
      </div>

      {/* suggestions */}
      <Suggestions suggestions={suggestions} />

      <ProductJsonLd
        productName={product?.title}
        images={product?.photos}
        description={product?.description}
        brand={product?.brand?.name}
        reviews={product?.comments?.map((comment) => ({
          author: {
            type: 'Person',
            name: comment?.client?.name,
          },
          datePublished: comment?.createdAt?.toISOString(),
          reviewBody: comment?.text,
          reviewRating: {
            bestRating: comment?.rating?.toString(),
            ratingValue: comment?.rating?.toString(),
            worstRating: comment?.rating?.toString(),
          },
        }))}
        aggregateRating={{
          ratingValue: String(product?.rating),
          reviewCount: String(product?.comments?.length),
        }}
      />
    </div>
  );
}
