import React from 'react';
import Link from 'next/link';
import nProgress from 'nprogress';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { SIGN_OUT } from '../../../graphql/mutations';
import { useShopCart } from '../../../context/CartContext';
import { Category } from '../../../models';
import {
  useUser,
  useNotify,
  useCurrency,
  useActiveCurrency,
} from '../../../hooks';
import {
  CartIcon,
  LoginIcon,
  LogoutIcon,
  UserIcon,
  AddressIcon,
} from '../../icons';
import SearchBar from './SearchBar';
import NavbarItem from './NavbarItem';
import navbarRoutes from '../../../routes/navbarRoutes';

interface NavbarProps {
  categories: Category[];
  updateIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Navbar({ categories, updateIsOpen }: NavbarProps) {
  const router = useRouter();
  const [user, setUser] = useUser();
  const [shopCart] = useShopCart();
  const [currencies] = useCurrency();
  const [logOut, setLogOut] = React.useState(false);
  const notify = useNotify();
  const [signOut] = useMutation(SIGN_OUT);
  const [activeCurrency, setActiveCurrency] = useActiveCurrency();

  const logout = async () => {
    try {
      if (!logOut) {
        nProgress.start();
        setLogOut(true);
        const { data } = await signOut();
        if (data.signOut.success) {
          setUser(null);
          localStorage.setItem('loggedIn', '0');
          router.push('/enter');
        }
      }
    } catch (err) {
      notify(err.message, 'danger');
      setLogOut(false);
    } finally {
      nProgress.done();
    }
  };

  const login = async () => {
    router.push('/enter');
  };

  const onChangeCurrency = (e) => {
    e.preventDefault();
    let changed = false;

    currencies.forEach((currency) => {
      if (currency.slug !== activeCurrency.slug && !changed) {
        setActiveCurrency(currency);
        changed = true;
      }
    });
  };

  return (
    <header className="w-full top-0 left-0">
      <nav className="w-full">
        {/* Upper navbar */}
        <div className="h-auto md:h-20 w-full flex flex-col md:flex-row relative md:justify-between items-center px-0 xs:px-5 md:px-16 xl:px-20">
          {/* image */}
          <Link href="/" passHref>
            <img
              className="h-10 mt-5 md:mt-0 cursor-pointer"
              src="/img/logo.png"
              alt=""
              style={{ left: '4%' }}
            />
          </Link>

          {/* search bar */}
          <SearchBar />

          {/* options */}
          <div className="h-14 w-full bg-white font-bold text-xs sm:text-sm text-secondary-400 flex flex-row justify-between items-center px-5 md:h-full md:relative md:w-auto md:px-0 mt-3 md:mt-0">
            <Link href="/products/">
              <a className="mr-4 cursor-pointer uppercase hover:text-secondary-300">
                Productos
              </a>
            </Link>

            <button
              type="button"
              aria-label="Currency"
              className="focus:outline-none focus-within:ring-0"
              onClick={onChangeCurrency}
            >
              <p className="mr-4 cursor-pointer uppercase font-bold hover:text-secondary-300">
                {`Moneda (${activeCurrency?.symbol.toUpperCase()})`}
              </p>
            </button>

            <button
              type="button"
              aria-label="Location"
              className="focus:outline-none focus-within:ring-0"
              onClick={() => updateIsOpen(true)}
            >
              <AddressIcon className="mr-4 h-5 cursor-pointer" type="gray" />
            </button>

            {user ? (
              <>
                <Link href="/shopping-cart">
                  <a className="flex mr-4">
                    <CartIcon
                      className="h-5 cursor-pointer"
                      type={shopCart?.items?.length ? 'done' : 'gray'}
                    />
                    {shopCart?.items?.length > 0 && (
                      <p className="ml-1 text-done-100">
                        {shopCart?.items?.length}
                      </p>
                    )}
                  </a>
                </Link>

                <Link href="/profile" as="/profile">
                  <a>
                    <UserIcon className="h-5 mr-4 cursor-pointer" type="gray" />
                  </a>
                </Link>
                <button
                  type="button"
                  aria-label="Logout"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                  className="h-5 focus:outline-none focus-within:ring-0"
                >
                  <LogoutIcon className="h-5 cursor-pointer" type="gray" />
                </button>
              </>
            ) : (
              <Link href="/enter" passHref>
                <button
                  type="button"
                  aria-label="Login"
                  onClick={(e) => {
                    e.preventDefault();
                    login();
                  }}
                >
                  <LoginIcon className="h-5 cursor-pointer" type="gray" />
                </button>
              </Link>
            )}
          </div>
        </div>

        {/* Lower navbar */}
        <ul className="h-16 w-full justify-evenly bg-primary-400 uppercase text-white font-bold items-center flex flex-row flex-wrap content-evenly sm:h-10 sm:grid sm:grid-cols-7 sm:grid-rows-1 sm:gap-0 list-none">
          {navbarRoutes.map((route) => (
            <NavbarItem
              key={route._id}
              text={route.text}
              categoryId={
                categories?.find(
                  (c) => c?.name?.toLowerCase() === route.text.toLowerCase()
                )?._id ?? 'promotions'
              }
            />
          ))}
        </ul>
      </nav>
    </header>
  );
}
