import React from 'react';
import { getTailwindColor } from '../../lib/iconHelper';

interface AddressIconProps {
  className?: string;
  type?: string;
}

export default function AddressIcon({ className, type }: AddressIconProps) {
  const tailwindColor = getTailwindColor(type);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={`fill-current ${tailwindColor} ${className}`}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </svg>
  );
}
