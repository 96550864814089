export function getTailwindColor(type: string): string {
  let tailwindColor = '';

  switch (type) {
    case 'white':
      tailwindColor = 'text-white hover:text-gray-100';
      break;
    case 'light':
      tailwindColor = 'text-secondary-300 hover:text-secondary-200';
      break;
    case 'gray':
      tailwindColor = 'text-secondary-400 hover:text-secondary-300';
      break;
    case 'dark-gray':
      tailwindColor = 'text-gray-600 hover:text-gray-500';
      break;
    case 'dark':
      tailwindColor = 'text-secondary-500 hover:text-secondary-400';
      break;
    case 'bodebar':
      tailwindColor = 'text-primary-400 hover:text-primary-300';
      break;
    case 'done':
      tailwindColor = 'text-done-100';
      break;

    default:
      tailwindColor = 'text-white hover:text-gray-100';
      break;
  }

  return tailwindColor;
}
