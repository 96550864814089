import React from 'react';

interface LoaderProps {
  middle?: boolean;
}

export default function Loader({ middle = false }: LoaderProps) {
  return (
    <div>
      <div
        className={`bg-white w-full max-w-sm p-10 flex flex-col items-center ${
          middle
            ? 'absolute transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
            : ''
        }`}
      >
        <img alt="" className="h-12" src="/img/logo.png" />
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}
