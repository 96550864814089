import gql from 'graphql-tag';

// User
export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE($data: UpdateProfileInput!) {
    updateProfile(data: $data) {
      name
      email
      slug
      active
      _id
      privilege
      client {
        _id
        shopCart {
          _id
          items {
            _id
            variant1
            variant2
            variant3
            product {
              _id
              title
              description
              slug
              brand {
                name
                slug
              }
              categories {
                name
                slug
              }
            }
            variantValue {
              _id
            }
            quantity
            price
            photo
            taxes {
              name
              value
            }
          }
          createdAt
          updatedAt
        }
        orders {
          _id
          status
          paid
          code
          total
          createdAt
          charges {
            ref
            amount
            bank
            method
          }
        }
        address {
          _id
          country
          state
          municipality
          street
        }
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;

// Session
export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const SIGN_IN = gql`
  mutation SIGN_IN($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      name
      email
      slug
      active
      _id
      privilege
      client {
        _id
        shopCart {
          _id
          items {
            _id
            variant1
            variant2
            variant3
            product {
              title
              description
              slug
              brand {
                name
                slug
              }
              categories {
                name
                slug
              }
            }
            quantity
            price
            photo
            taxes {
              name
              value
            }
          }
          createdAt
          updatedAt
        }
        orders {
          _id
          status
          paid
          code
          total
          createdAt
          charges {
            ref
            amount
            bank
            method
          }
        }
        address {
          _id
          country
          state
          municipality
          street
        }
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation SIGN_UP($data: SignUpInput!) {
    signUp(data: $data) {
      name
      email
      slug
      active
      _id
      privilege
      client {
        _id
        shopCart {
          _id
          items {
            _id
            variant1
            variant2
            variant3
            product {
              title
              description
              slug
              brand {
                name
                slug
              }
              categories {
                name
                slug
              }
            }
            quantity
            price
            photo
            taxes {
              name
              value
            }
          }
          createdAt
          updatedAt
        }
        address {
          _id
          country
          state
          municipality
          street
        }
      }
    }
  }
`;

// Shopcart
export const ADD_ITEM_TO_SHOP_CART = gql`
  mutation ADD_ITEM_TO_SHOP_CART($data: AddItemToCartInput!) {
    addItemToCart(data: $data) {
      client {
        user {
          name
          slug
          _id
        }
      }
      items {
        _id
        variant1
        variant2
        variant3
        product {
          _id
          title
          description
          slug
          photos
          price
          compareAtPrice
        }
        variantValue {
          _id
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
`;

export const INCREASE_ONE_ITEM_OF_CART = gql`
  mutation INCREASE_ONE_ITEM_OF_CART($data: IncreaseItemOfCartInput!) {
    increaseOneItemOfCart(data: $data) {
      _id
      items {
        _id
        variant1
        variant2
        variant3
        product {
          _id
          title
          description
          slug
          brand {
            name
            slug
          }
          categories {
            name
            slug
          }
          volumeSales {
            active
            discount
            discountType
            volume
          }
        }
        variantValue {
          _id
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
`;

export const DECREASE_ONE_ITEM_OF_CART = gql`
  mutation DECREASE_ONE_ITEM_OF_CART($data: DecreaseItemOfCartInput!) {
    decreaseOneItemOfCart(data: $data) {
      _id
      items {
        _id
        variant1
        variant2
        variant3
        product {
          _id
          title
          description
          slug
          brand {
            name
            slug
          }
          categories {
            name
            slug
          }
          volumeSales {
            active
            discount
            discountType
            volume
          }
        }
        variantValue {
          _id
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_ITEM_FROM_CART = gql`
  mutation REMOVE_ITEM_FROM_CART($data: RemoveItemFromCartInput!) {
    removeItemFromCart(data: $data) {
      _id
      items {
        _id
        variant1
        variant2
        variant3
        product {
          _id
          title
          description
          slug
          brand {
            name
            slug
          }
          categories {
            name
            slug
          }
        }
        variantValue {
          _id
        }
        taxes {
          name
          value
        }
        quantity
        price
        photo
      }
      createdAt
      updatedAt
    }
  }
`;

// Order
export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($data: CreateOrderInput!) {
    createOrder(data: $data) {
      total
      _id
      status
      code
      products {
        title
      }
      client {
        name
      }
    }
  }
`;

// Comment
export const CREATE_COMMENT = gql`
  mutation CREATE_COMMENT($data: CreateCommentInput!) {
    createComment(data: $data) {
      _id
    }
  }
`;
