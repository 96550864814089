import React from 'react';
import { getTailwindColor } from '../../lib/iconHelper';

interface AddIconProps {
  className?: string;
  type?: string;
}

export default function AddIcon({ className, type }: AddIconProps) {
  const tailwindColor = getTailwindColor(type);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={`fill-current ${tailwindColor} ${className}`}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </svg>
  );
}
