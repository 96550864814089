// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import React from 'react';
import Head from 'next/head';
import NProgress from 'nprogress';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../lib/useApollo';
import Layout from '../components/layouts/Layout';
import FacebookPixel from '../components/layouts/FacebookPixel';
import GoogleAnalytics from '../components/layouts/GoogleAnalytics';

import '../styles.css';

// @ts-expect-error becase err
function MyApp({ Component, pageProps, err }: AppProps) {
  const router = useRouter();
  const apolloClient = useApollo(pageProps.initialApolloState);

  React.useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });
    router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });
    router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, [router.events]);

  return (
    <ApolloProvider client={apolloClient}>
      <>
        <Head>
          <title>BodeBar</title>
        </Head>
        <Layout>
          <FacebookPixel>
            <GoogleAnalytics>
              <Component {...pageProps} err={err} />
            </GoogleAnalytics>
          </FacebookPixel>
        </Layout>
      </>
    </ApolloProvider>
  );
}

export default MyApp;
