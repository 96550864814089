import React from 'react';
import Modal from '../layouts/Modal';

interface WarningModalProps {
  isOpen: boolean;
  close: () => void;
}

export default function WarningModal({ isOpen, close }: WarningModalProps) {
  return (
    <Modal isOpen={isOpen}>
      <div className="h-auto px-6 py-5">
        <div className="h-96 bg-white w-80 flex flex-col justify-center items-center text-center border rounded shadow">
          <img alt="" src="/img/full-logo.png" className="w-1/2 mb-5" />
          <p className="px-7 mb-2">
            La siguiente página contiene venta de licores. Al continuar, usted
            certifica que está en edad apta para su compra
          </p>
          <button
            type="button"
            className="mx-2 text-white px-5 py-2 rounded outline-none focus:outline-none bg-primary-500"
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          >
            Sí, soy mayor de 18 años
          </button>
        </div>
      </div>
    </Modal>
  );
}
