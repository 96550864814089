import React from 'react';
import { User, Setting, Currency, Plugin } from '../models';

export interface AppContextProps {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  settings?: Setting;
  setSettings?: React.Dispatch<React.SetStateAction<Setting>>;
  locationId?: string;
  currencies?: Currency[];
  setCurrencies?: React.Dispatch<React.SetStateAction<Currency[]>>;
  activeCurrency?: Currency;
  setActiveCurrency?: React.Dispatch<React.SetStateAction<Currency>>;
  plugins?: Plugin[];
  setPlugins?: React.Dispatch<React.SetStateAction<Plugin[]>>;
}

const AppContext = React.createContext<AppContextProps>({});

export default AppContext;
