/* eslint-disable react/no-danger */
import React from 'react';
import { AboutUs } from '../models';
import PageHeader from './shared/PageHeader';

interface AboutUsProps {
  aboutUs: AboutUs;
}

export default function AboutUsView({ aboutUs }: AboutUsProps) {
  return (
    <div className="w-full">
      <PageHeader title="Nosotros" />
      <div className="w-full px-10 sm:px-28 py-16">
        <div
          className="w-full px-10 py-7 border-4 border-primary-400 rounded-lg"
          dangerouslySetInnerHTML={{
            __html: aboutUs?.text ?? '',
          }}
        />
      </div>
    </div>
  );
}
