import React from 'react';
import { useActiveCurrency } from '../../../hooks';
import { formatMoney } from '../../../lib/formats';
import { VolumeSale } from '../../../models';

interface VolumeSaleBoxProps {
  volumeSale: VolumeSale;
  units: number;
  unitPrice: number;
  setUnits: React.Dispatch<React.SetStateAction<number>>;
}

export default function VolumeSaleBox({
  volumeSale,
  units,
  unitPrice,
  setUnits,
}: VolumeSaleBoxProps) {
  const [activeCurrency] = useActiveCurrency();
  const [volume, setVolume] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);

  React.useEffect(() => {
    setVolume(volumeSale?.volume ?? 0);
    setDiscount(volumeSale?.discount ?? 0);
  }, [volumeSale]);

  return (
    <button
      type="button"
      disabled={units < volume}
      className="relative border pl-4 pr-6 py-2 cursor-pointer text-sm text-left transition-all hover:bg-gray-100"
      onClick={(e) => {
        e.preventDefault();
        setUnits(volume);
      }}
    >
      <p className="w-full text-center font-semibold mb-1 text-secondary-500 text-2sm sm:text-base">
        {volumeSale?.name}
      </p>
      <div className="flex flex-col xs:flex-row flex-wrap items-center xs:items-start justify-center">
        <p className="text-secondary-400 line-through xs:mr-2">
          {activeCurrency?.symbol}{' '}
          {formatMoney(
            unitPrice * volume * (activeCurrency?.rate / 100),
            activeCurrency?.symbol
          )}
        </p>
        <p className="text-primary-500 font-bold">
          {activeCurrency?.symbol}{' '}
          {formatMoney(
            unitPrice *
              volume *
              (discount / 100) *
              (activeCurrency?.rate / 100),
            activeCurrency?.symbol
          )}
        </p>
      </div>
    </button>
  );
}
