import React from 'react';
import { FacebookIcon, InstagramIcon } from '../../icons';
import footerRoutes from '../../../routes/footerRoutes';
import FooterItem from './FooterItem';

export default function Footer() {
  const dataWidth = 'w-full md:w-1/3 lg:w-1/4';
  return (
    <footer className="h-auto pb-20 sm:pb-0 w-full bg-secondary-500">
      <ul className="h-auto w-full flex flex-col md:flex-row list-none">
        {footerRoutes.map((route) => (
          <FooterItem {...route} key={route.text} />
        ))}
      </ul>
      <div className="h-auto w-full px-10 md:px-20 py-10 flex flex-col md:flex-row justify-center text-white text-sm text-center">
        <div className={`${dataWidth} px-7 mb-4 flex flex-col items-center`}>
          <p className="text-primary-300 font-semibold mb-2">
            Acerca de Nosotros
          </p>
          Licorería y Charcutería,
          <p>Charculicores, C.A.</p>
          <p>Rif: J-00353470-6</p>
        </div>
        <div className={`${dataWidth} px-7 mb-4 flex flex-col items-center`}>
          <p className="text-primary-300 font-semibold mb-2">
            Información de contacto
          </p>
          <a href="mailto:ventas@bodebar.com.ve">ventas@bodebar.com.ve</a>
          <p className="mt-2">+58 412 2123214</p>
          <p>+58 212 9912412</p>
          <p>+58 424 4029964</p>
        </div>
        <div
          className={`${dataWidth} px-7 mb-4 flex flex-col items-center text-center`}
        >
          <p className="text-primary-300 font-semibold mb-2">Ubicación</p>
          <p>Calle Mucuchies de las Mercedes</p>
          Caracas - Venezuela
          <div className="mt-1">
            <a
              className="border-b"
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/BodeBar/@10.4834139,-66.8649309,16z/data=!4m5!3m4!1s0x0:0x53e1eb56d9ddf6ea!8m2!3d10.483872!4d-66.8616588"
            >
              Ver en GoogleMaps
            </a>
          </div>
        </div>
        <div
          className={`${dataWidth} px-7 mb-4 flex flex-col items-center text-center`}
        >
          <p className="text-primary-300 font-semibold mb-2">
            Horario de atención
          </p>
          <p>11:00 am a 8:00 pm</p>
          <p>Lunes a Sábado</p>
        </div>
      </div>
      <div className="w-full flex justify-center flex-row mt-3">
        <div className="px-2">
          <a
            href="https://www.facebook.com/bodebarccs/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon className="h-5" />
          </a>
        </div>
        <div className="px-2">
          <a
            href="https://instagram.com/bodebarccs/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon className="h-5" />
          </a>
        </div>
      </div>
      <div className="h-auto w-full px-5 py-6 block md:flex md:justify-center md:items-center text-center text-white font-bold text-xs">
        Copyright © {new Date().getFullYear()} Bodebar. Todos los Derechos
        Reservados. Desarrollado por
        <a
          className="ml-1"
          target="_blank"
          rel="noreferrer"
          href="https://avilatek.dev"
        >
          Avila Tek
          <svg
            className="h-6 w-6 xl:h-6 xl:w-6 inline ml-1"
            viewBox="0, 0, 400,229.36096718480138"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M285.838 37.694 C 283.225 38.298,281.341 39.149,278.515 40.999 C 273.392 44.353,273.873 43.532,255.243 80.688 C 246.043 99.039,238.426 114.108,238.318 114.176 C 238.209 114.244,232.940 103.932,226.608 91.260 C 215.050 68.129,213.601 65.425,211.612 63.272 C 202.368 53.263,184.407 54.366,177.088 65.391 C 176.408 66.417,169.533 79.887,161.811 95.325 L 147.772 123.393 137.997 103.869 C 126.656 81.220,125.237 79.087,119.765 76.478 L 118.480 75.865 118.480 66.294 C 118.480 57.885,118.414 56.698,117.934 56.514 C 117.633 56.398,115.457 56.304,113.098 56.304 L 108.808 56.304 108.808 65.112 L 108.808 73.921 107.309 73.921 C 101.486 73.921,93.854 78.391,90.406 83.823 C 89.281 85.596,31.779 200.562,31.779 201.039 C 31.779 201.265,92.039 201.382,209.154 201.382 C 326.951 201.382,386.528 201.266,386.528 201.037 C 386.528 200.455,310.318 48.238,309.188 46.564 C 304.465 39.566,294.355 35.726,285.838 37.694 "
              stroke="none"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
    </footer>
  );
}
