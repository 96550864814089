import React from 'react';

interface BannerProps {
  text: string;
  display: boolean;
}

export default function Banner({ text, display }: BannerProps) {
  const [auxDisplay, setDisplay] = React.useState(display);
  return (
    <div
      className={`${
        auxDisplay ? 'relative' : 'hidden'
      } h-auto w-full flex items-center justify-center bg-secondary-500 text-white uppercase`}
    >
      <p className="p-3 w-2/3 break-words text-center">{text}</p>
      <button
        type="button"
        className="absolute h-6 w-6 right-3 text-base focus:outline-none focus-within:ring-0"
        style={{ zIndex: 200 }}
        onClick={(e) => {
          e.preventDefault();
          setDisplay(false);
        }}
      >
        <p className="h-6 w-6 flex items-center justify-center">x</p>
      </button>
    </div>
  );
}
