import React from 'react';

interface PageHeaderProps {
  title: string;
}

const PageHeader = ({ title }: PageHeaderProps) => (
  <div
    className="w-full h-48 sm:h-60 md:h-96 relative overflow-hidden flex justify-center items-center text-center"
    style={{
      background:
        'linear-gradient(0deg, rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6)), url(/img/header.jpg)',
      backgroundSize: '100% auto',
      backgroundPosition: 'center 62%',
    }}
  >
    <h1 className="text-4xl sm:text-6xl uppercase font-bold text-secondary-100">
      {title}
    </h1>
  </div>
);

export default PageHeader;
