import React from 'react';
import { getTailwindColor } from '../../lib/iconHelper';

interface FacebookIconProps {
  className?: string;
  type?: string;
}
export default function FacebookIcon({ className, type }: FacebookIconProps) {
  const tailwindColor = getTailwindColor(type);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 9 14"
      className={`fill-current ${tailwindColor} ${className}`}
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#FBFBFB"
          d="M5.414 13.615V7.601h2.008l.303-2.347H5.429c0-.627-.006-1.232.005-1.837a.835.835 0 01.442-.671.85.85 0 01.446-.1c.418-.02.84-.015 1.257-.02h.208V.53A12.5 12.5 0 005.54.47a2.77 2.77 0 00-1.563.628 2.823 2.823 0 00-.975 2.124c-.03.616-.015 1.231-.02 1.847v.198H.975V7.61h1.998v6.005"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H7.84V14H0z" transform="translate(.438)" />
        </clipPath>
      </defs>
    </svg>
  );
}
