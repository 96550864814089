import React from 'react';
import { Comment } from '../../../models';
import ProductRating from './Rating';

interface ProductCommentProps {
  comment: Comment;
}

export default function ProductComment({ comment }: ProductCommentProps) {
  return (
    <article className="w-full flex flex-col flex-wrap mb-4 px-4 py-4 bg-gray-50 rounded shadow">
      <div className="w-full inline-flex justify-between mb-2">
        <span className="w-1/2 mr-2 my-auto text-sm leading-normal font-semibold break-words">
          {comment?.client?.name}
        </span>
        <ProductRating rating={comment?.rating} readonly />
      </div>
      <p className="w-full leading-relaxed mt-2 break-words">{comment?.text}</p>
    </article>
  );
}
