import React from 'react';
import { useShopCart } from '../../../context/CartContext';
import { useActiveCurrency } from '../../../hooks';
import { formatMoney } from '../../../lib/formats';
import { volumeDiscounts } from '../../../lib/volumeDiscounts';
import { OrderProduct } from '../../../models';
import SummaryDetails from './SummaryDetails';

interface ItemRowProps {
  item: OrderProduct;
}

const SummaryRow = ({ item }: ItemRowProps) => {
  const [total, setTotal] = React.useState(0);
  const [units, setUnits] = React.useState(item?.quantity);
  const [shopCart] = useShopCart();
  const [activeCurrency] = useActiveCurrency();

  React.useEffect(() => {
    setUnits(item.quantity);
    setTotal(
      volumeDiscounts(item?.quantity, item?.price, item?.volumeSales ?? []) *
        (activeCurrency?.rate / 100)
    );
  }, [item, shopCart, activeCurrency]);

  const padding = 'py-2 sm:py-0';
  return (
    <tr className="w-full h-auto py-5 sm:flex sm:items-center">
      {/* details */}
      <td
        className={`h-full sm:w-2/3 flex justify-center items-center ${padding}`}
      >
        <SummaryDetails
          title={item?.product?.title ?? item?.title}
          category={item?.product?.categories ?? []}
          url={item?.photo}
          units={units}
          total={total}
        />
      </td>
      {/* units */}
      <td
        className={`h-full sm:w-1/6 hidden sm:flex justify-center items-center ${padding}`}
      >
        <div className="flex justify-center items-center">
          <p className="sm:hidden mr-2">Unidades</p>
          <div className="flex px-2 py-1 justify-center border border-secondary-300 sm:border-none items-center text-sm font-bold">
            {units}
          </div>
        </div>
      </td>
      {/* total */}
      <td
        className={`h-full sm:w-1/6 hidden sm:flex justify-center items-center text-center font-semibold ${padding}`}
      >
        <p
          className={`${
            activeCurrency?.slug !== 'dlar' ? 'text-xs' : 'text-sm'
          }`}
        >
          {`${activeCurrency?.symbol} ${formatMoney(
            total,
            activeCurrency?.symbol
          )}`}
        </p>
      </td>
    </tr>
  );
};

export default SummaryRow;
