import React from 'react';
import { getTailwindColor } from '../../lib/iconHelper';

interface GoBackIconProps {
  className?: string;
  type?: string;
}

export default function GoBackIcon({ className, type }: GoBackIconProps) {
  const tailwindColor = getTailwindColor(type);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={`fill-current ${tailwindColor} ${className}`}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
    </svg>
  );
}
