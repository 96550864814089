import { v4 as uuid } from 'uuid';

const navbarRoutes = [
  {
    _id: uuid(),
    text: 'Whisky',
  },
  {
    _id: uuid(),
    text: 'Ron',
  },
  {
    _id: uuid(),
    text: 'Vinos',
  },
  {
    _id: uuid(),
    text: 'Licores',
  },
  {
    _id: uuid(),
    text: 'Vodka',
  },
  {
    _id: uuid(),
    text: 'Otros',
  },
  {
    _id: uuid(),
    text: 'Promociones',
  },
];

export default navbarRoutes;
