import React from 'react';
import Link from 'next/link';
import { formatMoney } from '../../lib/formats';
import { Order } from '../../models';
import { useActiveCurrency } from '../../hooks';

interface OrderCardProps {
  order: Order;
}

const OrderCard = ({ order }: OrderCardProps) => {
  const [activeCurrency] = useActiveCurrency();
  const dataStyle = 'pr-4 lg:pr-8 font-bold';

  return (
    <div className="h-auto w-full border-2 mb-6 border-secondary-300 rounded-md p-8 uppercase">
      <div className="flex justify-between flex-col md:flex-row flex-wrap text-sm lg:text-base">
        {order?.location ? (
          <h3 className="md:hidden text-primary-400 font-semibold text-sm mb-3">
            {order?.location?.name || ''}
          </h3>
        ) : null}

        <div className="flex">
          <p className={dataStyle}>Fecha</p>
          <p>{String(order?.createdAt).slice(0, 10)}</p>
        </div>

        <p className="text-secondary-500 hidden md:block">|</p>

        <div className="flex">
          <p className={dataStyle}>Total</p>
          <p>
            {activeCurrency?.symbol}
            {formatMoney(
              order?.total * (activeCurrency?.rate / 100),
              activeCurrency?.symbol
            )}
          </p>
        </div>

        <p className="text-secondary-500 hidden md:block">|</p>

        <div className="hidden md:flex">
          <p className={dataStyle}>ID</p>
          <p>{order?.code}</p>
        </div>
      </div>

      <div className="w-full flex justify-between items-end mt-8">
        <h3 className="hidden md:block text-primary-400 font-semibold text-sm">
          {order?.location?.name || ''}
        </h3>
        <Link
          href="/profile/my-orders/[id]"
          as={`/profile/my-orders/${order?._id}`}
        >
          <a className="w-full md:w-1/3 text-center cursor-pointer bg-primary-400 px-1 py-2 uppercase rounded-md text-secondary-100 font-bold tracking-wide text-sm sm:px-5">
            Ver detalles
          </a>
        </Link>
      </div>
    </div>
  );
};

export default OrderCard;
