import React from 'react';
import { Product, VariantValue } from '../../../models';
import Title from '../../home/HomeTitle';
import ProductCard from '../ProductCard';

interface SuggestionsProps {
  suggestions: { product: Product; variantValue: VariantValue }[];
}

export default function Suggestions({ suggestions }: SuggestionsProps) {
  return (
    <div className="w-full">
      <Title title="Productos similares" />
      <div className="w-full flex flex-col justify-center items-center md:grid md:grid-cols-2 lg:grid-cols-3 gap-5">
        {suggestions.map((suggestion) => (
          <ProductCard key={suggestion.variantValue._id} {...suggestion} />
        ))}
      </div>
    </div>
  );
}
