import React from 'react';
import { Order } from '../../models';
import DoneIcon from '../icons/DoneIcon';

interface ThankYouProps {
  order: Order;
}

export default function ThankYou({ order }: ThankYouProps) {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <img alt="" className="w-52 sm:w-64 lg:w-72 mb-7" src="/img/logo.png" />
      <p className="uppercase text-secondary-500 font-bold pb-5 text-xl lg:text-2xl">
        Gracias por elegirnos
      </p>
      <DoneIcon className="h-12 sm:h-16 lg:h-20 mb-10" type="done" />
      {order?.charges[0]?.method?.toLowerCase() === 'zelle' && (
        <div className="flex flex-col justify-center items-center text-center px-10 sm:px-0">
          <p className="sm:text-lg mb-2">
            Recuerde anexar en la transferencia el siguiente código:
          </p>
          <p className="sm:text-xl font-bold border border-secondary-400 rounded-md p-5">
            {order?.code}
          </p>
        </div>
      )}
    </div>
  );
}
