import React from 'react';
import Link from 'next/link';
import { v4 as uuid } from 'uuid';
import { useMutation } from '@apollo/client';
import { useNotify, useUser } from '../../../hooks';
import { CREATE_COMMENT } from '../../../graphql/mutations';
import { Comment } from '../../../models';
import ProductComment from './ProductComment';
import ProductRating from './Rating';

interface CommentSectionProps {
  comments: Comment[];
  productId: string;
}

export default function CommentSection({
  comments = [],
  productId,
}: CommentSectionProps) {
  const [user] = useUser();
  const [createComment] = useMutation(CREATE_COMMENT);
  const notify = useNotify();
  const [rating, setRating] = React.useState(0);
  const [text, setText] = React.useState('');
  const [newComments, setNewComments] = React.useState<Comment[]>([]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const comment = {
        text,
        rating: parseInt(String(rating), 10),
        userId: user?._id,
        productId,
      };
      await createComment({
        variables: {
          data: comment,
        },
      });
      setNewComments([...newComments, comment]);
      setText('');
      notify('¡Comentario agregado!', 'success');
    } catch (err) {
      notify(err.message, 'danger');
    }
  };

  return (
    <div className="w-full flex flex-col flex-wrap">
      {comments?.length ? (
        <>
          <h3 className="mb-6 text-xl leading-normal text-gray-600">
            Opiniones de los clientes
          </h3>
          <section className="flex flex-col flex-wrap w-full mx-auto">
            {comments?.map((comment) => (
              <ProductComment key={comment?._id} comment={comment || {}} />
            ))}
          </section>
        </>
      ) : (
        <h3 className="mb-6 text-lg text-center leading-normal text-secondary-500">
          Se el primero en opinar
        </h3>
      )}
      {newComments?.length ? (
        <section className="flex flex-col flex-wrap w-full mx-auto">
          {newComments?.map((comment) => (
            <article
              key={comment?._id || uuid()}
              className="w-full flex flex-col flex-wrap mb-4 px-4 py-4 bg-gray-50 rounded shadow"
            >
              <div className="w-full inline-flex">
                <span className="mr-2 my-auto text-sm leading-normal">
                  {user?.name}
                </span>
                <ProductRating rating={comment?.rating} readonly />
              </div>
              <p className="leading-relaxed mt-2">{comment?.text}</p>
            </article>
          ))}
        </section>
      ) : null}
      {user ? (
        <form
          method="POST"
          className="flex flex-col flex-wrap w-full mx-auto px-8 py-6 rounded-md bg-secondary-500 shadow-md"
          onSubmit={onSubmit}
        >
          <h3 className="text-secondary-100 text-lg font-bold leading-normal mb-5">
            Escriba su opinion
          </h3>

          <label htmlFor="opinion" className="block">
            <textarea
              name="opinion"
              className="form-textarea mt-1 block w-full rounded"
              placeholder="Escriba la reseña de este producto"
              rows={5}
              value={text}
              onChange={(e) => {
                e.preventDefault();
                setText(e.target.value);
              }}
              required
            />
          </label>
          <div className="w-full inline-flex mt-2 text-sm leading-normal">
            <p className="my-auto mr-2 text-secondary-100">
              ~ {user?.name ?? 'Anónimo'}
            </p>
            <ProductRating rating={rating} updateRating={setRating} />
          </div>
          <button
            type="submit"
            className="rounded text-center bg-primary bg-secondary-400 text-white cursor-pointer mt-4 px-4 py-2 mx-auto md:ml-auto md:mr-0 hover:bg-primary-400"
          >
            Comentar
          </button>
        </form>
      ) : (
        <div className="w-full text-center">
          <Link href="/enter">
            <a className="rounded text-center bg-primary text-secondary-400 cursor-pointer px-4 py-2 mr-auto">
              Iniciar Sesión
            </a>
          </Link>
        </div>
      )}
    </div>
  );
}
