import React from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import { CURRENT_USER } from '../graphql/queries';
import { User, ShopCart } from '../models';

export interface CartContextProps {
  shopCart?: ShopCart;
  setShopCart?: React.Dispatch<React.SetStateAction<ShopCart>>;
  refetch?: (variables?: Partial<Record<string, any>>) => Promise<
    ApolloQueryResult<{
      me: User;
    }>
  >;
}

export const CartContext = React.createContext<CartContextProps>({});

export function useShopCart(): readonly [
  ShopCart,
  React.Dispatch<React.SetStateAction<ShopCart>>
] {
  const { shopCart, setShopCart } = React.useContext(CartContext);
  return [shopCart, setShopCart] as const;
}

export function useRefetchShopCart() {
  const { refetch } = React.useContext(CartContext);
  return refetch;
}

interface CartContextProviderProps {
  children?: React.ReactNode;
}

export default function CartContextProvider({
  children,
}: CartContextProviderProps) {
  const { data, loading, refetch } = useQuery<{ me: User }>(CURRENT_USER, {
    fetchPolicy: 'network-only',
  });

  const [shopCart, setShopCart] = React.useState<ShopCart>({});

  React.useEffect(
    function syncShopCartState() {
      if (!loading && data) {
        setShopCart(data?.me?.client?.shopCart ?? {});
      }
    },
    [data, loading]
  );

  return (
    <CartContext.Provider value={{ shopCart, setShopCart, refetch }}>
      {children}
    </CartContext.Provider>
  );
}
