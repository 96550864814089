const footerRoutes = [
  {
    text: 'Inicio',
    href: '/',
    as: '/',
  },
  {
    text: 'Productos',
    href: '/products',
    as: '/products',
  },
  {
    text: 'Términos y condiciones',
    href: '/terms-of-service',
    as: '/terms-of-service',
  },
  {
    text: 'Preguntas frecuentes',
    href: '/frequently-asked-questions',
    as: '/frequently-asked-questions',
  },
  {
    text: 'Cuenta',
    href: '/profile',
    as: '/profile',
  },
  {
    text: 'Nosotros',
    href: '/about-us',
    as: '/about-us',
  },
];

export default footerRoutes;
