import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { GET_PLUGIN } from '../../graphql/queries';
import { Plugin } from '../../models';
import * as ga from '../../lib/gtag';

export default function GoogleAnalytics({ children }) {
  const router = useRouter();

  const { data } = useQuery<{
    plugin?: Plugin;
  }>(GET_PLUGIN, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        name: 'Google Analytics',
      },
    },
  });

  const getPluginData = (plugin: Plugin, name: string) => {
    if (!plugin?.active) return ''; // If not active, return empty string
    return (
      plugin?.credentials?.find((c) => c?.name?.toLowerCase() === name)
        ?.value ?? ''
    );
  };

  const handleRouteChange = (url) => {
    ga.pageview(url, getPluginData(data?.plugin, 'token'));
  };

  React.useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  return children;
}
