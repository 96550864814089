import React from 'react';
import { getTailwindColor } from '../../lib/iconHelper';

interface ArrowIconProps {
  className?: string;
  type?: string;
  direction: string;
}

export default function ArrowIcon({
  className,
  type,
  direction,
}: ArrowIconProps) {
  const tailwindColor = getTailwindColor(type);
  return (
    <>
      {direction === 'right' ? (
        <svg
          className={`fill-current ${tailwindColor} ${className}`}
          viewBox="0 0 75 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.3333 26L52.8333 37.5L41.3333 26ZM52.8333 37.5L41.3333 49L52.8333 37.5ZM52.8333 37.5H22.1667H52.8333ZM72 37.5C72 42.0306 71.1076 46.5168 69.3738 50.7026C67.6401 54.8883 65.0988 58.6916 61.8952 61.8952C58.6916 65.0988 54.8883 67.6401 50.7026 69.3738C46.5168 71.1076 42.0306 72 37.5 72C32.9694 72 28.4832 71.1076 24.2974 69.3738C20.1117 67.6401 16.3084 65.0988 13.1048 61.8952C9.90119 58.6916 7.35994 54.8883 5.62616 50.7026C3.89237 46.5168 3 42.0306 3 37.5C3 28.35 6.63481 19.5748 13.1048 13.1048C19.5748 6.63481 28.35 3 37.5 3C46.65 3 55.4252 6.63481 61.8952 13.1048C68.3652 19.5748 72 28.35 72 37.5Z"
            stroke="#95353A"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          className={`fill-current ${tailwindColor} ${className}`}
          viewBox="0 0 75 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.1667 48.5L21.6667 37L33.1667 48.5ZM21.6667 37L33.1667 25.5L21.6667 37ZM21.6667 37H52.3333H21.6667ZM2.5 37C2.5 32.4694 3.39237 27.9832 5.12616 23.7974C6.85994 19.6117 9.40119 15.8084 12.6048 12.6048C15.8084 9.40119 19.6117 6.85994 23.7974 5.12616C27.9832 3.39237 32.4694 2.5 37 2.5C41.5306 2.5 46.0168 3.39237 50.2026 5.12616C54.3883 6.85994 58.1916 9.40119 61.3952 12.6048C64.5988 15.8084 67.1401 19.6117 68.8738 23.7974C70.6076 27.9832 71.5 32.4694 71.5 37C71.5 46.15 67.8652 54.9252 61.3952 61.3952C54.9252 67.8652 46.15 71.5 37 71.5C27.85 71.5 19.0748 67.8652 12.6048 61.3952C6.13481 54.9252 2.5 46.15 2.5 37V37Z"
            stroke="#95353A"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
}
