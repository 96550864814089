import React from 'react';
import { useActiveCurrency } from '../../hooks';
import { formatMoney } from '../../lib/formats';
import { Order } from '../../models';
import CheckoutRow from '../checkout/summary/SummaryRow';

interface OrderDetailsProps {
  order: Order;
}

const OrderDetails = ({ order }: OrderDetailsProps) => {
  const [activeCurrency] = useActiveCurrency();
  let status = '';
  let style = '';

  switch (Number(order?.status)) {
    case 0:
      status = 'Por pagar';
      style = 'text-white bg-secondary-300';
      break;
    case 1:
      status = 'Check administrativo';
      style = 'text-white bg-secondary-300';
      break;
    case 2:
      status = 'Pagado';
      style = 'text-white bg-done-100';
      break;
    case 3:
      status = 'Entregado';
      style = 'text-white bg-done-100';
      break;
    case 4:
      status = 'Crédito';
      style = 'text-white bg-secondary-300';
      break;
    case 5:
      status = 'Crédito entregado';
      style = 'text-white bg-done-100';
      break;
    case 6:
      status = 'Crédito pagado';
      break;
    case 7:
      status = 'Anulada';
      style = 'text-white bg-extra-100';
      break;
    default:
      status = 'Por pagar';
      style = 'text-white bg-secondary-300';
      break;
  }

  const dataStyle = 'pr-4 lg:pr-8 font-bold';

  return (
    <div className="w-full px-2 md:px-5 lg:px-20 py-24 flex flex-col justify-center items-center">
      <div className="h-auto w-full text-sm sm:text-base border border-secondary-500 rounded-md p-5 sm:p-10 mb-5">
        <h1 className="uppercase text-secondary-500 font-bold text-xl sm:text-2xl mb-3 text-center">
          Datos de la orden
        </h1>
        {order?.location ? (
          <div className="flex justify-between">
            <p className={dataStyle}>Sucursal: </p>
            <p className="font-semibold text-primary-400">
              {order?.location?.name}
            </p>
          </div>
        ) : null}
        <div className="flex justify-between">
          <p className={dataStyle}>Estado: </p>
          <p className={`${style} rounded px-2`}>{status}</p>
        </div>
        <div className="flex justify-between">
          <p className={dataStyle}>Código: </p>
          <p>{order?.code}</p>
        </div>
        <div className="flex justify-between">
          <p className={dataStyle}>Envío: </p>
          <p>{order?.shipping?.name}</p>
        </div>
        <div className="flex justify-between">
          <p className={dataStyle}>Subtotal: </p>
          <p>
            {activeCurrency?.symbol}
            {formatMoney(
              order?.subtotal * (activeCurrency?.rate / 100),
              activeCurrency?.symbol
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p className={dataStyle}>Descuento: </p>
          <p>
            {activeCurrency?.symbol}
            {formatMoney(
              order?.discount * (activeCurrency?.rate / 100),
              activeCurrency?.symbol
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p className={dataStyle}>Cargos adicionales: </p>
          <p>
            {activeCurrency?.symbol}
            {formatMoney(
              order?.extraFees * (activeCurrency?.rate / 100),
              activeCurrency?.symbol
            )}
          </p>
        </div>
        {order?.taxes?.map((tax) => (
          <div className="flex justify-between">
            <p className={dataStyle}>{tax?.name}: </p>
            <p>
              {activeCurrency?.symbol}
              {formatMoney(
                tax?.value * (activeCurrency?.rate / 100),
                activeCurrency?.symbol
              )}
            </p>
          </div>
        ))}
        <div className="flex justify-between">
          <p className={dataStyle}>Total: </p>
          <p>
            {activeCurrency?.symbol}
            {formatMoney(
              order?.total * (activeCurrency?.rate / 100),
              activeCurrency?.symbol
            )}
          </p>
        </div>
      </div>

      <div className="h-auto w-full border border-secondary-500 rounded-md p-5 sm:p-10 mb-5">
        <h1 className="uppercase text-secondary-500 font-bold text-xl sm:text-2xl mb-3 text-center">
          Lista de productos
        </h1>
        <table className="w-full">
          <thead className="hidden md:flex uppercase text-secondary-400">
            <tr className="w-full flex">
              <th className="w-2/3 text-left pl-5 pb-10 text-xs lg:text-base">
                Producto
              </th>
              <th className="w-1/6 pb-10 text-xs lg:text-base">Cantidad</th>
              <th className="w-1/6 pb-10 text-xs lg:text-base">Total</th>
            </tr>
          </thead>
          <tbody className="flex flex-col">
            {order?.products?.map((product) => (
              <CheckoutRow key={product?._id} item={product} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderDetails;
