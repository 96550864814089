import React from 'react';

interface InputProps {
  type: 'number' | 'text' | 'password' | 'email' | 'select' | 'checkbox';
  name: string;
  setState: (value: string) => void;
  placeholder?: string;
  withLabel?: boolean;
  labelText?: string;
  value: number | string | readonly string[];
  checked?: boolean;
  children?: React.ReactChild[];
}

const Input = ({
  type,
  name,
  withLabel,
  labelText,
  placeholder,
  setState,
  children,
  value,
  checked,
  ...rest
}: InputProps) => {
  if (type === 'select') {
    return (
      <div className="w-full mb-3">
        <select
          name={name}
          id={name}
          placeholder={placeholder}
          onChange={(e) => setState(e.target.value)}
        >
          {children}
        </select>
      </div>
    );
  }
  if (type === 'checkbox') {
    return (
      <div className="w-full mb-3">
        <input
          type={type}
          name={name}
          id={name}
          onChange={(e) => setState(e.target.value)}
          checked={checked}
          value={value}
          className="inline-block "
          {...rest}
        />
        <label
          htmlFor={name}
          className={`inline-block ml-2 text-sm leading-normal ${
            checked ? 'text-gray-800' : 'text-gray-500'
          }`}
        >
          {labelText}
        </label>
      </div>
    );
  }
  return (
    <div className="w-full mb-3">
      {withLabel ? (
        <label className="w-full block mb-1" htmlFor={name}>
          {labelText}:
        </label>
      ) : null}
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        className="bg-gray-100 shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight outline-none focus:shadow-outline"
        onChange={(e) => setState(e.target.value)}
        {...rest}
      />
    </div>
  );
};

export default Input;
