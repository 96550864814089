import React from 'react';

export default function SoldOutSign() {
  return (
    <div className="absolute w-full h-full flex justify-center items-center top-0 left-0">
      <p className=" bg-primary-500 px-3 py-1 transform -rotate-6 text-white text-xs uppercase">
        Agotado
      </p>
    </div>
  );
}
