import React from 'react';
import UserForm from './UserForm';

export default function Profile() {
  return (
    <div className="h-auto sm:h-full sm:ml-72 px-5 py-16 lg:px-20 md:py-24 lg:py-24 flex flex-col">
      <div className="w-full">
        <h1 className="uppercase text-secondary-500 tracking-wide font-bold text-3xl mb-12">
          Perfil
        </h1>
      </div>
      <UserForm />
    </div>
  );
}
