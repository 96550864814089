import React from 'react';
import Link from 'next/link';

interface FooterItemProps {
  text: string;
  href?: string;
  as?: string;
}

export default function FooterItem({ text, href, as }: FooterItemProps) {
  return (
    <li className="md:h-full w-full md:w-1/5 px-4 py-4 hover:bg-secondary-400 cursor-pointer uppercase font-bold text-white text-sm text-center flex justify-center items-center">
      <Link href={href} as={as}>
        <a className="h-full w-full text-center flex justify-center items-center">
          {text}
        </a>
      </Link>
    </li>
  );
}
