import React from 'react';
import nProgress from 'nprogress';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { validateEmail, validateString } from 'avilatek-utils';
import { UPDATE_PROFILE } from '../../graphql/mutations';
import { User } from '../../models';
import { useUser, useNotify } from '../../hooks';
import { FaceIcon, AddressIcon } from '../icons';
import directions from '../../lib/directions';

export default function UserForm() {
  const router = useRouter();
  const notify = useNotify();
  const [user, setUser] = useUser();
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  // User data
  const [name, setName] = React.useState(user?.name ?? '');
  const [email, setEmail] = React.useState(user?.email ?? '');
  const [dni, setDni] = React.useState(user?.dni ?? '');
  const [dniType, setDniType] = React.useState(user?.dniType ?? 0);
  const [phone, setPhone] = React.useState(user?.client?.phone ?? '');

  // Address
  const [country, setCountry] = React.useState('Venezuela');
  const [state, setState] = React.useState(
    user?.client?.address?.[0]?.state ?? ''
  );
  const [municipality, setMunicipality] = React.useState(
    user?.client?.address?.[0]?.municipality ?? ''
  );
  const [street, setStreet] = React.useState(
    user?.client?.address?.[0]?.street ?? ''
  );

  // Address Data
  const [municipalities, setMunicipalities] = React.useState<string[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>(null);

  // Sign up method
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      // First validations
      if (
        !validateString(name) ||
        String(name).length >= 90 ||
        /\d/.test(name)
      ) {
        return setError('Ingrese un nombre válido');
      }
      if (!validateEmail(email) || String(email).length >= 90) {
        return setError('Ingrese un correo válido');
      }
      if (!validateString(street)) {
        return setError('Ingrese una dirección válida');
      }
      if (String(dni).length > 9) {
        return setError('Ingrese una cédula válida');
      }
      if (
        !validateString(phone) ||
        String(phone)?.length >= 16 ||
        String(phone)?.length <= 6
      ) {
        return setError('Ingrese un número válido');
      }

      setLoading(true);
      nProgress.start();

      if (!loading) {
        const { data } = await updateProfile({
          variables: {
            data: {
              email,
              name,
              dni,
              dniType: String(dniType),
              phone,
              address: {
                _id: user?.client?.address?.[0]?._id ?? '',
                country,
                state,
                municipality,
                street,
              },
            },
          },
        });
        if (data.updateProfile) {
          setUser({ ...data.updateProfile } as User);
          notify('Cambios guardados', 'success');
        }
      }
    } catch (err) {
      notify('Ocurrió un error', 'danger');
    } finally {
      setLoading(false);
      nProgress.done();
    }
  };

  const onCardClick = (card) => {
    if (card === 'orders') {
      router.push('/profile/my-orders');
    } else {
      router.push('/shopping-cart');
    }
  };

  React.useEffect(() => {
    if (state) {
      setMunicipalities(
        directions?.find(({ state: _state }) => _state === state)
          ?.municipalities
      );
    }
  }, [state]);

  return (
    <form
      className="h-auto w-full md:px-10 lg:mx-auto flex flex-col flex-wrap justify-center items-center"
      method="POST"
      onSubmit={onSubmit}
    >
      <div className="py-8 text-gray-800 bg-white w-full">
        {error ? (
          <div className="bg-red-400 w-full mb-4 p-2 rounded-sm text-white border border-red-500 shadow-md">
            {error}
          </div>
        ) : null}
        <div className="flex w-full">
          {/* User data */}
          <div className="lg:mr-4 w-1/2">
            <div className="pb-3 mb-4 w-full text-center flex flex-col justify-center items-center border-b-2 border-primary-400">
              <FaceIcon className="h-8 mb-1" type="bodebar" />
              <h2 className="text-lg text-primary-400 font-semibold ">
                Datos de Usuario
              </h2>
            </div>
            {/* Name */}
            <label htmlFor="name" className="w-full block mb-4">
              <span className="">
                {dniType !== 2 ? 'Nombre y Apellido' : 'Nombre Fiscal'}
              </span>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Ingresa un nombre..."
                className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                value={name}
                onChange={(e) => {
                  e.preventDefault();
                  setName(e.target.value);
                }}
              />
            </label>
            {/* DNI */}
            <div className="w-full block mb-4">
              <span className="">Cédula</span>
              <div className="w-full flex">
                <label htmlFor="dnyType" className="w-1/4">
                  <select
                    className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                    id="dnyType"
                    name="dnyType"
                    value={dniType}
                    onChange={(e) => {
                      e.preventDefault();
                      setDniType(Number(e.target.value));
                    }}
                  >
                    <option id="national" value="0">
                      V
                    </option>
                    <option id="foreigner" value="1">
                      E
                    </option>
                    <option id="judicial" value="2">
                      J
                    </option>
                    <option id="government" value="3">
                      G
                    </option>
                  </select>
                </label>
                <label htmlFor="dni" className="w-3/4">
                  <input
                    type="text"
                    name="dni"
                    id="dni"
                    placeholder="Ingresa tu cédula..."
                    className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                    value={dni}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.target;
                      if (Number(value) || value === '') setDni(value);
                    }}
                  />
                </label>
              </div>
            </div>
            {/* Phone */}
            <label htmlFor="phone" className="w-full block mb-4">
              <span className="">
                Teléfono {dniType === 2 && 'de contacto'}
              </span>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Ingresa tu teléfono..."
                className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                value={phone}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  if (Number(value) || value === '' || value === '0')
                    setPhone(value);
                }}
              />
            </label>
            {/* Email */}
            <label htmlFor="email" className="w-full block mb-4">
              <span className="">Correo {dniType === 2 && 'de contacto'}</span>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Ingresa tu correo..."
                className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
              />
            </label>
          </div>
          {/* Location data */}
          <div className="lg:ml-4 w-1/2">
            <div className="pb-3 mb-4 w-full text-center flex flex-col justify-center items-center border-b-2 border-primary-400">
              <AddressIcon className="h-8 mb-1" type="bodebar" />
              <h2 className="text-lg text-primary-400 font-semibold ">
                Dirección {dniType === 2 && 'Fiscal'}
              </h2>
            </div>
            {/* Country */}
            <label htmlFor="country" className="w-full block mb-4">
              <span className="">País</span>
              <select
                className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                id="country"
                name="country"
                value={country}
                onChange={(e) => {
                  e.preventDefault();
                  setCountry(e.target.value);
                }}
              >
                <option id="ven" value="Venezuela">
                  Venezuela
                </option>
              </select>
            </label>
            {/* State */}
            <label htmlFor="state" className="w-full block mb-4">
              <span className="">Estado</span>
              <select
                required
                className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                id="state"
                name="state"
                value={state}
                onChange={(e) => {
                  e.preventDefault();
                  setState(e.target.value);
                }}
              >
                <option id="" value="">
                  Seleccione su estado
                </option>
                {directions.map(({ _id, state: _state }) => (
                  <option key={_id} id={String(_id)} value={_state}>
                    {_state}
                  </option>
                ))}
              </select>
            </label>
            {/* Municipality */}
            <label htmlFor="municipality" className="w-full block mb-4">
              <span className="">Municipio</span>
              <select
                required
                className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                id="municipality"
                name="municipality"
                value={municipality}
                onChange={(e) => {
                  e.preventDefault();
                  setMunicipality(e.target.value);
                }}
              >
                <option id="" value="">
                  Seleccione su municipio
                </option>
                {municipalities.map((m) => (
                  <option key={m} id={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            </label>
            {/* Street */}
            <label htmlFor="street" className="w-full block mb-4">
              <span className="">Calle</span>
              <input
                required
                type="text"
                name="street"
                id="street"
                placeholder="Ingresa tu calle..."
                className="bg-gray-100 shadow border rounded py-2 px-3 leading-tight outline-none focus:shadow-outline w-full mt-1"
                value={street}
                onChange={(e) => {
                  e.preventDefault();
                  setStreet(e.target.value);
                }}
              />
            </label>
          </div>
        </div>
        {/* Button */}
        <div className="flex flex-col items-center justify-center text-left mt-4">
          <button
            type="submit"
            className="block w-1/2 rounded transition duration-500 ease-out text-center bg-primary-400 text-white cursor-pointer px-4 py-1 lg:p-2 lg:px-4"
          >
            Guardar cambios
          </button>
        </div>
        {/* Boxes */}
        <div className="h-auto w-full p-5 flex justify-center">
          <button
            type="button"
            className="h-40 w-40 bg-primary-400 rounded-md flex flex-col justify-center items-center text-white font-bold mr-2"
            onClick={(e) => {
              e.preventDefault();
              onCardClick('orders');
            }}
          >
            <p className="uppercase">Órdenes</p>
            <p className="text-5xl mt-2">{user?.client?.orders?.length}</p>
          </button>

          <button
            type="button"
            className="h-40 w-40 bg-primary-400 rounded-md flex flex-col justify-center items-center text-white font-bold ml-2"
            onClick={(e) => {
              e.preventDefault();
              onCardClick('cart');
            }}
          >
            <p className="uppercase">En Carrito</p>
            <p className="text-5xl mt-2">
              {user?.client?.shopCart?.items?.length}
            </p>
          </button>
        </div>
      </div>
    </form>
  );
}
