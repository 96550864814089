import React from 'react';
import Modal from '../layouts/Modal';
import { Location } from '../../models';
import { useLocationID } from '../../hooks';
import { CloseIcon } from '../icons';

interface LocationModalProps {
  locations?: Location[];
  isOpen?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  updateIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LocationModal({
  isOpen,
  locations = [],
  onChange,
  updateIsOpen,
}: LocationModalProps) {
  const locationID = useLocationID();

  return (
    <Modal isOpen={isOpen}>
      <div className="p-7 bg-gray-100">
        <div className="w-full flex justify-between">
          <h2 className="font-bold text-primary-400 uppercase">Sucursal</h2>
          {locationID ? (
            <button
              type="button"
              aria-label="Close modal"
              className="focus:outline-none focus-within:ring-0 h-5 w-5 p-0 m-0"
              onClick={() => updateIsOpen(false)}
            >
              <CloseIcon className="h-5 cursor-pointer" type="gray" />
            </button>
          ) : null}
        </div>
        <hr className="border-secondary-200 my-3" />
        <h2 className="text-gray-700">Por favor, seleccione una sucursal</h2>
        <label className="block mt-4 mb-2" htmlFor="location">
          <span className="text-gray-700 sr-only">Sucursales</span>
          <select
            name="location"
            className="form-select mt-1 block w-full"
            onChange={onChange}
            value={locationID || 'none'}
            defaultValue="none"
          >
            <option disabled value="none">
              Seleccione una Sucursal
            </option>
            {locations?.map((location) => (
              <option value={location?._id} key={location?._id}>
                {location?.name}
              </option>
            ))}
          </select>
        </label>
      </div>
    </Modal>
  );
}
