import React from 'react';
import { useRouter } from 'next/router';

interface NavbarItemProps {
  text: string;
  categoryId: string;
}

export default function NavbarItem({ text, categoryId }: NavbarItemProps) {
  const router = useRouter();
  return (
    <li className="h-1/2 sm:h-full w-1/4 sm:w-auto hover:bg-primary-500 cursor-pointer">
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          localStorage.setItem('category', categoryId);
          router.push('/products');
        }}
        className="h-full w-full text-center"
      >
        <a className="h-full w-full text-center flex justify-center items-center text-xs lg:text-sm tracking-wide uppercase">
          {text}
        </a>
      </button>
    </li>
  );
}
